import moment from 'moment';

export function myToString(str) {
    if (!str) return '';
    return str.toString().toLowerCase().trim();
}

export function isSimilarObject(obj1, obj2) {
    return (JSON.stringify(obj1) === JSON.stringify(obj2));
}

export function setToken(value) {
    localStorage.setItem('token', value);
}

export function getToken() {
    return localStorage.getItem('token');
}

export function getHeader(tokenParam) {
    let token = tokenParam || getToken();
    return { 'Authorization': 'Token ' + token }
}

export function dateTimeToDayName(dateTime) {
    let date = moment(dateTime, "YYYY-MM-DD HH:mm");
    let dateText = '';

    if (date.isValid()) {
        let dateNum = moment.duration(moment().diff(date)).asDays();
        dateText = moment(date).format("DD-MM-YYYY");

        if (dateNum >= 1 && dateNum <= 2) dateText = 'Вчера';
        if (dateNum >= 0 && dateNum <= 1) dateText = 'Сегодня';
        if (dateNum >= -1 && dateNum <= -2) dateText = 'Завтра';
        if (dateNum >= -2 && dateNum <= -3) dateText = 'Послезавтра';
    }
    return dateText;
}

export function localDateTimeToUTC(dateTime) {
    if (!moment(dateTime, "DD/MM/YYYY HH:mm").isValid()) return null;

    let UTCDateTime = moment(dateTime).utc().format('DD/MM/YYYY HH:mm');
    let formatted = moment(UTCDateTime, "DD/MM/YYYY HH:mm");
    return moment(formatted).format('YYYY-MM-DD HH:mm')
}

export function UTCDateTimeToLocal(dateTime) {
    if (!dateTime) return null;
    let date = new Date(dateTime);
    if (!moment(date).isValid()) return null;

    var stillUtc = moment.utc(dateTime).toDate();
    // return moment(stillUtc).local().format('DD-MM-YYYY HH:mm');
    return moment(stillUtc).local().format('YYYY-MM-DD HH:mm');
}

export function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};