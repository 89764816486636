import React, { Component } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

import ErrorStroke from '../Commons/ErrorStroke';
import { isSimilarObject } from '../../utils/helpers';

export class SignInForm extends Component {
    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            data,
            errors,
            isFetching,
            handleSubmit,
            handleOnChange,
        } = this.props;
        const {params, buttonDisable, authorize} = data;

        let newErrorState = Object.assign({}, errors);
        let disable = (buttonDisable || isFetching);

        return (
            <Form>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Логин</Form.Label>
                    <Form.Control
                        value={params.username}
                        onChange={(e) => handleOnChange('username', e.target.value)}
                        type="text" placeholder="Введите имя пользователя" />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Пароль</Form.Label>
                    <Form.Control 
                        value={params.password}
                        onChange={(e) => handleOnChange('password', e.target.value)}
                        type="password" placeholder="Пароль" />
                </Form.Group>

                <ErrorStroke error={newErrorState.non_field_errors}/>

                {
                    authorize
                    ? <Alert variant="success">Авторизация успешна!</Alert>
                    : null
                }
                
                <Button
                    onClick={(e) => handleSubmit(e)}
                    disabled={disable || false}
                    variant="primary"
                    type="submit">
                    {isFetching ? 'Подожите...' : 'Войти'}
                </Button>
            </Form>
        )
    }
}