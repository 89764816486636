import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import { isSimilarObject } from '../../../../utils/helpers';

export default class DataTable extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const { data } = this.props;

        return (
            <Table className="task-table" style={{marginTop:'15px'}} striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Исполнитель</th>
                        <th>затраченное время</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(data.data).map((key, index) => {
                            let item = data.data[key];
                            return  <ListItem
                                        key={index}
                                        index={index}
                                        item={item} />
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

DataTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    data: PropTypes.object,
};