export default {
    users: {
        data: {},
        ids: [],
    },
    sessions: {},
    task: {
        data: {},
        ids: [],
    },
    project: {
        data: {},
        ids: [],
    },
    milestones: {
        data: {},
        ids: [],
    },
    checkList: {
        data: {},
        ids: [],
    },
    timeTrack: {
        data: {},
        ids: [],
    },
}