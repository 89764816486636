import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter, Route } from 'react-router-dom';
import AppWrap from './AppWrap';
import store from "./config/store";
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/bootstrap.min.css';
import './assets/css/main.css';
import * as serviceWorker from './serviceWorker';
import TasksMain from './containers/moduleTask/index'
const taskModul = document.querySelector('#task-module'),
    rootModul = document.querySelector('#root');

ReactDOM.render(
    <Provider store={store}>
        <AppWrap/>
    </Provider>,
document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();