import axios from 'axios';
import * as types from './actionTypes';
import { API } from '../utils/Constants';
import { getHeader } from '../utils/helpers';


function getCheckListSuccess(payload) {
    return {
        type: types.LOAD_CHECKLIST_SUCCESS,
        payload,
    }
}

function getCheckListFailure(errors) {
    return {
        type: types.LOAD_CHECKLIST_FAILURE,
        errors
    }
}

export function getCheckList(args = {}) {
    return function(dispatch) {
        let url = `${API}checklist_task/`;
        let params = Object.assign({}, {
            page_size: 20
        }, args);

        if(args.url) {
            url = args.url;
            delete args.url;
        }
        
        dispatch({type: types.LOAD_CHECKLIST_REQUEST});

        return axios.get(
            url,
            {
                params,
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("getCheckListSuccess success", response.data);
            dispatch(getCheckListSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getCheckListFailure error', errors.data)
            dispatch(getCheckListFailure(errors.data || {}));
        });
    }
}



function updateCheckListSuccess(payload) {
    return {
        type: types.UPDATE_CHECKLIST_SUCCESS,
        payload,
    }
}

function updateCheckListFailure(errors) {
    return {
        type: types.UPDATE_CHECKLIST_FAILURE,
        errors
    }
}

export function updateCheckList(params, id) {
    return function(dispatch) {
        
        dispatch(setProcessValue(id, 'update', true));
        dispatch({type: types.UPDATE_CHECKLIST_REQUEST});

        return axios.patch(
            `${API}checklist_task/${id}/`,
            params,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("updateCheckListSuccess success", response.data);
            dispatch(updateCheckListSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('updateCheckListFailure error', errors.data)
            dispatch(updateCheckListFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(id, 'update', false));
        });
    }
}



function createCheckListSuccess(payload) {
    return {
        type: types.CREATE_CHECKLIST_SUCCESS,
        payload,
    }
}

function createCheckListFailure(errors) {
    return {
        type: types.CREATE_CHECKLIST_FAILURE,
        errors
    }
}

export function createCheckList(params) {
    return function(dispatch) {
        
        dispatch({type: types.CREATE_CHECKLIST_REQUEST});

        return axios.post(
            `${API}checklist_task/`,
            params,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("createCheckListSuccess success", response.data);
            dispatch(createCheckListSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('createCheckListFailure error', errors.data)
            dispatch(createCheckListFailure(errors.data || {}));
        })
    }
}



function deleteCheckListSuccess(payload) {
    return {
        type: types.DELETE_CHECKLIST_SUCCESS,
        payload,
    }
}

function deleteCheckListFailure(errors) {
    return {
        type: types.DELETE_CHECKLIST_FAILURE,
        errors
    }
}

export function deleteCheckList(checkList) {
    return function(dispatch) {
        
        dispatch(setProcessValue(checkList.id, 'delete', true));
        dispatch({type: types.DELETE_CHECKLIST_REQUEST});

        return axios.delete(
            `${API}checklist_task/${checkList.id}/`,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("deleteCheckListSuccess success", response.data);
            dispatch(deleteCheckListSuccess(checkList));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('deleteCheckListFailure error', errors.data)
            dispatch(deleteCheckListFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(checkList.id, 'delete', false));
        });
    }
}



function setStateCheckListSuccess(payload) {
    return {
        type: types.SET_STATE_CHECKLIST_SUCCESS,
        payload,
    }
}

function setStateCheckListFailure(errors) {
    return {
        type: types.SET_STATE_CHECKLIST_FAILURE,
        errors
    }
}

export function setStateCheckList(params, id, type) {
    return function(dispatch) {
        
        dispatch(setProcessValue(id, type, true));
        dispatch({type: types.SET_STATE_CHECKLIST_REQUEST});

        return axios.post(
            `${API}checklist_task/${id}/${type}/`,
            {},
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("setStateCheckListSuccess success", response.data);
            dispatch(setStateCheckListSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('setStateCheckListFailure error', errors.data)
            dispatch(setStateCheckListFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(id, type, false));
        });
    }
}



function setProcessValue(item_id, action_name, action_state) {
    return {
        type: types.SET_CHECKLIST_PROCESS_VALUE,
        payload: {
            item_id,
            action_name,
            action_state,
        },
    }
}