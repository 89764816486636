import React, { Component } from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isSimilarObject } from '../../utils/helpers';

export default class EstimateBadge extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        let {data} = this.props;
        if(!data) return null;

        const {spent_hours, estimate_hours} = data;
        let badgeType = 'info';

        if(spent_hours > estimate_hours) {
            badgeType = 'danger';
        }
        else if(spent_hours === estimate_hours) {
            badgeType = 'warning';
        }

        return (
            <Badge variant={badgeType} title="Затраченное / Планируемое время">
                {`${spent_hours} / ${estimate_hours} ч.`}
            </Badge>
        )
    }
}

EstimateBadge.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    title: PropTypes.string,
    data: PropTypes.object.isRequired,
};