import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

import EstimateBadge from '../../Commons/EstimateBadge';
import { isSimilarObject, dateTimeToDayName } from '../../../utils/helpers';

export default class TasksTable extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            owner,
            created_at,
            currentTask,
            taskStateObject,
        } = this.props;

        let dateTime = `${dateTimeToDayName(created_at)} в ${moment(created_at).format('HH:mm')}`

        let infoArray = [];
        let ownerStr = owner ? `Создал задачу ${owner.first_name} ${owner.last_name}` : 'Постановщик не указан'
        infoArray.push(ownerStr)
        infoArray.push(dateTime);

        if(currentTask.reopen_count) {
            infoArray.push(`Количество переоткрытий: ${currentTask.reopen_count}`)
        }

        return (
            <Row className="task-state__container">
                <Col xs={12}>
                    <Button
                        title={taskStateObject.title}
                        variant={taskStateObject.color}
                        size="sm"
                        disabled>
                        {taskStateObject.shortTitle || taskStateObject.title}
                    </Button>
                    <span className="task-created">
                        {infoArray.join(", ")}
                    </span>
                    {' '}
                    <EstimateBadge data={{
                        spent_hours: currentTask.spent_hours,
                        estimate_hours: currentTask.estimate_hours
                    }}/>
                </Col>
            </Row>
        )
    }
}

TasksTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    created_at: PropTypes.any,
    currentTask: PropTypes.object.isRequired,
    taskStateObject: PropTypes.object.isRequired,
};