import React, { Component } from 'react';
import PropsTypes from 'prop-types';
import { Row, Col, Card, Button, ProgressBar } from 'react-bootstrap';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import ListItem from './ListItem';
import EmptyTextArea from './EmptyTextArea';
import TextEditor from './TextEditor';
import RemoveCheckList from '../../Modals/RemoveCheckList';
import { myToString, isSimilarObject } from '../../../../utils/helpers';

class CheckList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            deleteModal: false,
            show: (props.show) || false,
            list: props.list,
            progress: 0,
            viewMode: props.viewMode || false,

            editorIsActive: false,
            content: EditorState.createEmpty(),

            editContent: null,
            content2: this.initEmptyItem()
        }
    }

    componentDidMount() {
        this.setProgress();
    }

    componentDidUpdate(prevProps, prevState) {
        const {show, list} = this.props;

        if(prevState.list.length !== this.state.list.length) {
            this.setProgress();
        }

        if(prevProps.list.length !== list.length) {
            this.setState({list})
        }

        if(!isSimilarObject(prevState.list, this.state.list)) {
            this.setProgress();
        }
        
        if(!isSimilarObject(prevProps.list, list)) {
            this.setState({list});
        }
        
        if(prevProps.show !== show) {
            this.setState({
                show: this.props.show || false,
                list,
            })
        }
    }

    initEmptyItem() {
        return {
            content: EditorState.createEmpty(),
            state: "OPEN",
            id: null,
        }
    }

    getDefaultContent(content) {
        if(myToString(content) === '' || content === '<p></p>' || content === '<p></p>\n') {
            return EditorState.createEmpty();
        }
       
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(content)
            )
        );
    }

    handleOnChange = (item, type) => {
        let newType = type;
        
        if(newType === 'reopen') {
            newType = 'open'
        }

        let newParam = {
            ...item,
            state: newType.toUpperCase()
        }

        this.props.setStateCheckList(newParam, type);
    }

    editItem = (index, item) => {
        this.setState({
            editContent: index,
            editorIsActive: true,
            content2: {
                ...item,
                content: this.getDefaultContent(item.content),
            }
        });
    }

    deleteItem = (index, item) => {
        this.props.handleRemoveItem(item, index)
    }

    onCancel = () => {
        this.setState({
            editorIsActive: false,
            editContent: null,
            content2: this.initEmptyItem(),
        })
    }

    setProgress() {
        const {list} = this.state;
        const listLen = list.length;
        let resLen = list.filter(item => (item.state === "FINISH" || item.state === "CLOSE")).length;
        let progress = 0;

        if(listLen > 0 && resLen > 0) {
            progress = 100/listLen * resLen;
        }

        this.setState({progress});
    }

    addNewItem = (item) => {
        if(item.id || this.state.editContent !== null) {
            this.props.handleUpdateItem(item, this.state.editContent);
        }
        else {
            this.props.handleAddItem(item);
        }

        this.setState({
            editContent: null,
            content2: this.initEmptyItem()
        });
    }

    render() {
        const { progress, list, viewMode } = this.state;

        if(!this.state.show) return false;

        return (
            <Row>
                <Col xs={12}>

                    {
                        viewMode ? null :
                        <RemoveCheckList
                            show={this.state.deleteModal}
                            handleClose={() => this.setState({deleteModal: false})}
                            handleSubmit={this.props.cancelCheckList}
                        />
                    }

                    <Card>
                        <Card.Body>
                            <div style={{display:'flex', marginBottom: '.5rem', alignItems: 'center'}}>
                                <Card.Title style={{marginBottom: '0', flexGrow: 1}}>Чек-лист</Card.Title>
                                {
                                    (list.length || viewMode) ? null :
                                    <Button variant="danger" size="sm" onClick={this.props.cancelCheckList}>Отменить</Button>
                                }
                            </div>
                            <div>
                                <ProgressBar animated={false} now={progress} variant="success" style={{height: '6px'}}/>

                                <div className="checklist__percent">{(progress).toFixed(0)}%</div>
                            </div>
                            
                            {
                                list.map((item, index) => {
                                    return  <ListItem
                                                key={index}
                                                data={item}
                                                viewMode={viewMode}
                                                editItem={() => this.editItem(index, item)}
                                                deleteItem={() => this.deleteItem(index, item)}
                                                handleOnChange={(type) => this.handleOnChange(item, type)}
                                            />
                                })
                            }

                            {
                                viewMode ? null :
                                this.state.editorIsActive ?
                                <TextEditor
                                    content2={this.state.content2}
                                    addItem={this.addNewItem}
                                    onCancel={this.onCancel}
                                />
                                :
                                <EmptyTextArea onClick={() => this.setState({editorIsActive: true})}/>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        )
    }
}

export default CheckList;

CheckList.propTypes = {
    show: PropsTypes.bool.isRequired,
    list: PropsTypes.array,
    handleAddItem: PropsTypes.func,
    cancelCheckList: PropsTypes.func,
}