import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
// import Select from 'react-select';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';

import { isSimilarObject } from '../../utils/helpers';
import { projectStates } from '../../utils/Constants';

export default class TaskFilter extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            filters,
            handleFilters
        } = this.props
        // const {
        //     count,
        //     filters,
        //     userOptions,
        //     onOpenSelect,
        //     handleFilters,
        // } = this.props;

        // let contractorOptions = [
        //     {value: null, label: 'Исполнитель'},
        //     ...userOptions,
        // ]

        // let statusOptions = [
        //     {value: null, label: 'Все статусы'},
        //     ...Object.keys(projectStates).map(key => {
        //         return {value: key, label: projectStates[key].title}
        //     })
        // ]

        // const currentState = statusOptions.filter(item => item.value === filters.state)[0];
        // const currentContractor = userOptions.filter(item => item.value === parseInt(filters.contractor_id))[0];
        return (
            <Row>
                <Col className="col-params col-inline task-filter">
                    <Row className="task-filter__fields">                        
                        <Col xs={2} className="task-filter__col">
                            С
                            <DatePicker
                                selected={filters.from_date}
                                onChange={(date) => handleFilters('from_date', date)}
                                dateFormat='dd.MM.yyyy'
                            />
                        </Col>
                        <Col xs={2} className="task-filter__col">
                            По
                            <DatePicker
                                selected={filters.to_date}
                                onChange={(date) => handleFilters('to_date', date)}
                                dateFormat='dd.MM.yyyy'
                            />
                        </Col>
                        <Col xs={2} className="task-filter__col">
                            <select onChange={(e) => handleFilters('type_date', e.target.value)}>
                                <option value="plan_end_at">Планируемая дата платежа</option>
                                <option value="paid_at">Фактическая дата платежа</option>
                            </select>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

TaskFilter.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    filters: PropTypes.object.isRequired,
    handleFilters: PropTypes.func.isRequired,
};