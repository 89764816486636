import axios from 'axios';
import * as types from './actionTypes';
import { API } from '../utils/Constants';
import { getHeader } from '../utils/helpers';

function getMilestoneSuccess(payload) {
    return {
        type: types.LOAD_MILESTONE_SUCCESS,
        payload,
    }
}

function getMilestoneFailure(errors) {
    return {
        type: types.LOAD_MILESTONE_FAILURE,
        errors
    }
}

export function getMilestone(args = {}) {
    return function(dispatch) {
        let url = `${API}milestone/`;
        let params = Object.assign({}, {
            page_size: 9999
        }, args);

        if(args.url) {
            url = args.url;
            delete args.url;
        }
        
        dispatch({type: types.LOAD_MILESTONE_REQUEST});

        return axios.get(
            url,
            {
                params,
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("getMilestoneSuccess success", response.data);
            dispatch(getMilestoneSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getMilestoneFailure error', errors.data)
            dispatch(getMilestoneFailure(errors.data || {}));
        });
    }
}



function getMilestoneByIdSuccess(payload) {
    return {
        type: types.LOAD_MILESTONE_BY_ID_SUCCESS,
        payload,
    }
}

function getMilestoneByIdFailure(errors) {
    return {
        type: types.LOAD_MILESTONE_BY_ID_FAILURE,
        errors
    }
}

export function getMilestoneById(milestone) {
    return function(dispatch) {
        
        dispatch(setProcessValue(milestone.id, 'get', true));
        dispatch({type: types.LOAD_MILESTONE_BY_ID_REQUEST});

        return axios.get(
            `${API}milestone/${milestone.id}/`,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            dispatch(getMilestoneByIdSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getMilestoneByIdFailure error', errors.data)
            dispatch(getMilestoneByIdFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(milestone.id, 'get', false));
        });
    }
}



function updateMilestoneSuccess(payload) {
    return {
        type: types.UPDATE_MILESTONE_SUCCESS,
        payload,
    }
}

function updateMilestoneFailure(errors) {
    return {
        type: types.UPDATE_MILESTONE_FAILURE,
        errors
    }
}

export function updateMilestone(milestone, id) {
    return function(dispatch) {
        
        dispatch(setProcessValue(milestone.id, 'update', true));
        dispatch({type: types.UPDATE_MILESTONE_REQUEST});

        return axios.patch(
            `${API}milestone/${id}/`,
            milestone,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("updateMilestoneSuccess success", response.data);
            dispatch(updateMilestoneSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('updateMilestoneFailure error', errors.data)
            dispatch(updateMilestoneFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(milestone.id, 'update', false));
        });
    }
}



function createMilestoneSuccess(payload) {
    return {
        type: types.CREATE_MILESTONE_SUCCESS,
        payload,
    }
}

function createMilestoneFailure(errors) {
    return {
        type: types.CREATE_MILESTONE_FAILURE,
        errors
    }
}

export function createMilestone(task) {
    return function(dispatch) {
        
        dispatch({type: types.CREATE_MILESTONE_REQUEST});

        return axios.post(
            `${API}milestone/`,
            task,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("createMilestoneSuccess success", response.data);
            dispatch(createMilestoneSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('createMilestoneFailure error', errors.data)
            dispatch(createMilestoneFailure(errors.data || {}));
        });
    }
}



function setProcessValue(milestone_id, action_name, action_state) {
    return {
        type: types.SET_MILESTONE_PROCESS_VALUE,
        payload: {
            milestone_id,
            action_name,
            action_state,
        },
    }
}
