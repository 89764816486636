import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';

import Header from '../Commons/Header';
import PageTitle from '../Commons/PageTitle';
import { isSimilarObject } from '../../utils/helpers';

export default class DoesNotExists extends Component {
    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {

        const { errorMessage, onPress } = this.props;
        let title = `Ошибка! ${errorMessage || 'Задача не найдена'}`;

        return (
            <div>
                <Header />

                <Container className="general-container">
                    <PageTitle title={title}>
                        {
                            !onPress ? null :
                            <Button
                                variant="primary"
                                size="md"
                                onClick={() => onPress()}>
                                Новая задача
                            </Button>
                        }
                    </PageTitle>
                </Container>
            </div>
        )
    }
}