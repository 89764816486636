import * as types from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function reducer(state = initialState.milestones, action) {
    switch (action.type) {
        case types.LOAD_MILESTONE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.LOAD_MILESTONE_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    ..._.mapKeys(action.payload.results, 'id')
                },
                ids: [..._.union(state.ids, [..._.map(action.payload.results, 'id')])],
                count: action.payload.count,
                next: action.payload.next,
                timestamp: new Date(),
            });
        case types.LOAD_MILESTONE_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });


        case types.LOAD_MILESTONE_BY_ID_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.LOAD_MILESTONE_BY_ID_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: ((state.count || 0) + 1),
                next: state.next,
            });
        case types.LOAD_MILESTONE_BY_ID_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });


        case types.CREATE_MILESTONE_REQUEST:
            return Object.assign({}, state, {
                isCreating: true,
            });
        case types.CREATE_MILESTONE_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isCreating: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: ((state.count || 0) + 1),
                next: state.next,
            });
        case types.CREATE_MILESTONE_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isCreating: false,
            });


        case types.UPDATE_MILESTONE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.UPDATE_MILESTONE_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: (state.count || 0),
                next: state.next,
            });
        case types.UPDATE_MILESTONE_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
         
        
        default:
            return state;
    }
}



export function milestoneProcess(state = {}, action) {
    switch (action.type) {
        case types.SET_MILESTONE_PROCESS_VALUE:
            return Object.assign({}, state, {
                [action.payload.milestone_id]: {
                    ...(state[action.payload.milestone_id] || {}),
                    [action.payload.action_name]: action.payload.action_state
                }
            });
            
        
        default:
            return state;
    }
}