import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';

import { isSimilarObject } from '../../../../utils/helpers';

export default class ContractorField extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            label,
            options,
            defaultValue,
            onChange,
            onOpenSelect,
        } = this.props;

        return (
            <Form.Group>
                <Form.Label>{label}</Form.Label>
                <Select
                    options={options}
                    value={defaultValue}
                    className="select-custom"
                    placeholder="Не выбран"
                    onChange={item => onChange(item.value)}
                    onMenuOpen={() => onOpenSelect && onOpenSelect()}
                />
            </Form.Group>
        )
    }
}