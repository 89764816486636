import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ListGroup, Card, Button } from 'react-bootstrap';

import { isSimilarObject } from '../../../utils/helpers';

export default class TaskViewRightInfoBlock extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {task, contractor, handleActions} = this.props;

        // can_close: false
        // can_edit: false
        // can_finish: false
        // can_open: false
        // can_reopen: false
        // can_set_estimate: true
        // can_start_develop: true
        // can_stop_develop: false

        return (
            <div>
                <Card style={{ width: '20rem' }}>
                    <ListGroup variant="flush">
                        <ListGroup.Item>{`Исполнитель: ${contractor}`}</ListGroup.Item>
                        {/* <ListGroup.Item>{`План начало: ${task.plan_start_at || 'Не указано'}`}</ListGroup.Item>
                        <ListGroup.Item>{`План конец: ${task.plan_end_at || 'Не указан'}`}</ListGroup.Item> */}

                        {
                            task.can_edit ?
                            <ListGroup.Item>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleActions('edit')}>
                                    Редактировать
                                </Button>
                            </ListGroup.Item>
                            : null
                        }

                        {
                            task.can_stop_develop ?
                            <ListGroup.Item>
                                <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => handleActions('stop')}>
                                    Отложить
                                </Button>
                            </ListGroup.Item>
                            : null
                        }

                        {
                            task.can_finish ?
                            <ListGroup.Item>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleActions('finish')}>
                                    Выполнено
                                </Button>
                            </ListGroup.Item>
                            : null
                        }

                        {
                            task.can_reopen ?
                            <ListGroup.Item>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleActions('reopen')}>
                                    Переоткрыть
                                </Button>
                            </ListGroup.Item>
                            : null
                        }

                        {
                            task.can_start_develop ?
                            <ListGroup.Item>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleActions('start')}>
                                    Взять задачу
                                </Button>
                            </ListGroup.Item>
                            : null
                        }

                        {
                            task.can_close ?
                            <ListGroup.Item>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleActions('close')}>
                                    Проверено
                                </Button>
                            </ListGroup.Item>
                            : null
                        }

                        {
                            task.can_set_estimate ?
                            <ListGroup.Item>
                                <Button
                                    variant="primary"
                                    size="sm"
                                    onClick={() => handleActions('estimate_show')}>
                                    {task.estimate_hours ? 'Переоценить задачу' : 'Оценить задачу'}
                                </Button>
                            </ListGroup.Item>
                            : null
                        }
                    </ListGroup>
                </Card>
            </div>
        )
    }
}

TaskViewRightInfoBlock.propTypes = {
    contractor: PropTypes.any,
    task: PropTypes.object.isRequired,
    handleActions: PropTypes.func.isRequired,
}