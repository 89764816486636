import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import api from '../../api'
import { debounce } from '../../utils/helpers'


const DateInput = ({data, field, handleChange, localValue={}, minDate = null}) => {
    const date = localValue[field] ? localValue[field] : data[field];
    const localData = {...data, ...localValue}
    const minDateValue = localData[minDate] ? moment(localData[minDate]).toDate() : null;
    return <DatePicker
        selected={date ? moment(date).toDate() : null}
        onChange={(date) => handleChange(field, date)}
        dateFormat='dd.MM.yyyy'
        minDate={minDateValue}
    />
}

const PriceField = ({data, field, handleChange, localValue={}}) => {
    const value = localValue[field] ? localValue[field] : data[field];
    return <input onChange={({target}) => handleChange(field, target.value)} type="number" value={value ? value : 0} />
}

export default class ListItem extends Component {
    state = {
        currentMilestone: 0,
        localValue: {},
    }

    selectMilestone = (e) => {
        this.setState({
            currentMilestone: Number(e.target.value),
            // localValue: {},
        })
    }

    handleChange = (field, date) => {
        const efficientFn = this.update
        const { item } = this.props;
        const { milestones } = item;
        const { currentMilestone } = this.state;
        const localValue = {...this.state.localValue}
        if(!localValue[currentMilestone]) {
            localValue[currentMilestone] = {}
        }
        localValue[currentMilestone][field] = date;
        console.log(localValue, 'localValue')
        this.setState({
            localValue
        }, () => {
            const id = milestones[currentMilestone].id
            efficientFn(id, localValue[currentMilestone])
        })
    }

    update = debounce((id, params) => {
        api.updateMilestone({
            id,
            ...params
        })
        .then(res => {
            console.log(res)
        })
    }, 500)

    render() {
        const { 
            currentMilestone,
            localValue
        } = this.state
        const {item, contractor} = this.props;
        if(!item) return false;
        const { milestones } = item;
        const currentLocalValue = localValue[currentMilestone]
        return (
            <tr>
                <td><strong>{item.title}</strong></td>
                <td>{contractor ? (`${contractor.first_name} ${contractor.last_name}`) : 'Не указан'}</td>
                <td>
                    <select style={{ maxWidth: '300px' }} onChange={this.selectMilestone}>
                        {milestones.map((item, index) => {
                            return (
                                <option key={item.id} value={index}>{item.title}</option>
                            )
                        })}
                    </select>
                </td>
                <td>
                    {milestones.length > 0 ? (
                        <>
                            <div>
                                С
                                <DateInput
                                    local
                                    data={milestones[currentMilestone]}
                                    field={'plan_end_at'}
                                    localValue={currentLocalValue}
                                    handleChange={this.handleChange}
                                />
                            </div>
                            <div>
                                По
                                <DateInput
                                    local
                                    data={milestones[currentMilestone]}
                                    field={'to_plan_end_at'}
                                    localValue={currentLocalValue}
                                    handleChange={this.handleChange}
                                    minDate={'plan_end_at'}
                                />
                            </div>
                        </>
                    ) : null}
                </td>
                <td>
                    {milestones.length > 0 ? (
                        <>
                            <div>
                                C
                                <DateInput
                                    data={milestones[currentMilestone]}
                                    field={'paid_at'}
                                    localValue={currentLocalValue}
                                    handleChange={this.handleChange}
                                />
                            </div>
                            <div>
                                По
                                <DateInput
                                    data={milestones[currentMilestone]}
                                    field={'to_paid_at'}
                                    localValue={currentLocalValue}
                                    handleChange={this.handleChange}
                                    minDate={'paid_at'}
                                />
                            </div>
                        </>
                    ) : null}
                </td>
                <td>
                    {milestones.length > 0 ? (
                        <PriceField
                            data={milestones[currentMilestone]}
                            field={'price_paid'}
                            localValue={currentLocalValue}
                            handleChange={this.handleChange}
                        />
                    ) : null}
                </td>
            </tr>
        )
    }
}

ListItem.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    contractor: PropTypes.object,
    item: PropTypes.object.isRequired,
};