import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Container, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import Header from '../../components/Commons/Header';
import FinanceTable from '../../components/Finance/FinanceTable';
import PageTitle from '../../components/Commons/PageTitle';
import Filter from '../../components/Finance/Filter';
import LoadMore from '../../components/Commons/LoadMore';
import {getProject} from '../../actions/projectActions';
import { isSimilarObject } from '../../utils/helpers';
import { getUser } from '../../actions/userActions';
import api from '../../api'

class FinanceContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            projectList: [],
            isNext: false,
            isFetching: false,
            page: 1,
            filters: {
                from_date: null,
                to_date: null,
                type_date: 'plan_end_at',
            },
        }
    }

    componentDidMount() {
        this.fetching()
    }

    fetching = () => {
        const { isFetching, page, projectList, filters } = this.state
        if(isFetching) return false

        this.setState({
            isFetching: true
        }, () => {
            const { type_date, from_date, to_date } = filters
            let params = { page }
            console.log(type_date, 'type_date')
            if(type_date) {
                if(from_date) {
                    params[`from_${type_date}`] = moment(from_date).format('YYYY-MM-DD 00:00:00')
                }
                if(to_date) {
                    params[`to_${type_date}`] = moment(to_date).format('YYYY-MM-DD 00:00:00')
                }
            }
            api.getFinanceProjects(params)
            .then(({ data }) => {
                const { results, next } = data
                this.setState({
                    isFetching: false,
                    isNext: Boolean(next),
                    page: page + 1,
                    projectList: page === 1 ? results : [...projectList, ...results]
                })
            })
        })
    }

    // componentDidUpdate(prevProps, prevState) {
        
    // }

    // onOpenSelect = (field) => {
    //     const {dispatch, users} = this.props;

    //     if(field === 'contractor_id' && users.next) {
    //         dispatch(getUser({url: users.next}))
    //     }
    // }

    // sortObjectList(data) {
    //     if(!data || _.isEmpty(data)) return [];
    //     let value  =_.values(data);
    //     return _.orderBy(value, ['sort'], ['desc']);
    // }

    handleFilters = (field, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [field]: value,
            },
            page: 1,
        }, this.fetching);
    }

    // parseUrl(filters) {
    //     const params = new URLSearchParams(window.location.search);
    //     let newParams = {};

    //     for(var pair of params.entries()) {
    //         if(filters.hasOwnProperty(pair[0]) && pair[1]) {
    //             newParams[pair[0]] = pair[1];
    //         }
    //     }

    //     return newParams;
    // }

    // getParams(filters) {
    //     let paramsArray = [];

    //     for(let key in filters) {
    //         if(filters[key] && filters[key] !== '') {
    //             paramsArray.push(`${key}=${filters[key]}`)
    //         }
    //     }
    //     return paramsArray.join("&");
    // }

    render() {
        const { 
            projectList,
            isNext,
            isFetching,
        } = this.state;
        return (
            <div>
                <Header />

                <Container className="general-container">
                    
                    <PageTitle title="Финансы" />

                    <Filter
                        filters={this.state.filters}
                        // onOpenSelect={this.onOpenSelect}
                        handleFilters={this.handleFilters}
                    />

                    <Row>
                        <Col>
                            <FinanceTable
                                projects={projectList}
                            />
                        </Col>
                    </Row>

                    <LoadMore
                        next={isNext}
                        isFetching={isFetching}
                        onPress={this.fetching} />
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        projects: state.projects,
    }
}

export default connect(mapStateToProps)(FinanceContainer)