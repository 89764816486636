import React, {PureComponent} from 'react';
import {NavLink} from 'react-router-dom';
import {Navbar, Nav} from 'react-bootstrap';

export default class Header extends PureComponent {
    render() {
        return (
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="/">Garpix</Navbar.Brand>
                
                <Nav className="mr-auto">
                    <NavLink to="/tasks" className="header-link">
                        Задачи
                    </NavLink>
                    <NavLink to="/projects" className="header-link">
                        Проекты
                    </NavLink>
                    {/* <NavLink to="/plans_of_people" className="header-link">
                        План (люди)
                    </NavLink>
                    <NavLink to="/plan_of_projects" className="header-link">
                        План (проекты)
                    </NavLink> */}
                    <NavLink to="/finance" className="header-link">
                        Финансы
                    </NavLink>
                </Nav>
            </Navbar>
        )
    }
}
