import React, { Component } from 'react';
import { Badge } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { projectStates } from '../../utils/Constants';

export default class ListItem extends Component {

    handleOnPress(e, action) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onPress(action);
    }

    render() {
        const {item, contractor} = this.props;
        if(!item) return false;
        console.log(item.state)
        let stateObj = projectStates[item.state] || {};

        return (
            <tr onClick={(e) => this.handleOnPress(e, 'show')}>
                <td>
                    <Badge variant={stateObj.color} title={stateObj.title}>{stateObj.shortTitle}</Badge>
                    <strong>{` ${item.title}`}</strong>
                </td>
                <td>
                    {contractor ? (`${contractor.first_name} ${contractor.last_name}`) : 'Не указан'}
                </td>
            </tr>
        )
    }
}

ListItem.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    contractor: PropTypes.object,
    item: PropTypes.object.isRequired,
    onPress: PropTypes.func.isRequired,
};