import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Header from '../../components/Commons/Header';
import PageTitle from '../../components/Commons/PageTitle';
import DoesNotExists from '../../components/Task/DoesNotExists';
import {
    closeTask,
    reopenTask,
    getTaskById,
    estimateTask,
    stopDevelopTask,
    startDevelopTask,
    finishDevelopTask,
} from '../../actions/taskActions';
import {
    isSimilarObject,
    dateTimeToDayName,
    UTCDateTimeToLocal
} from '../../utils/helpers';
import { taskStates } from '../../utils/Constants';
import TaskViewRightInfoBlock from '../../components/Task/TaskView/TaskRightBlock';
import CheckList from '../../components/Task/TaskEdit/CheckList';
import EstimateModal from '../../components/Task/Modals/EstimateModal';
import TaskHeaderInfo from '../../components/Task/TaskView/TaskHeaderInfo';
import TaskDescription from '../../components/Task/TaskView/TaskDescription';
import { getCheckList, createCheckList, deleteCheckList, updateCheckList, setStateCheckList } from '../../actions/checkListActions';

class TaskSingleView extends Component {

    constructor(props) {
        super(props);
        
        let param_id = props.match.params.id;
        let currentTask = props.location.state;
        let isNew = (param_id === 'new');

        if(!isNew && param_id) {
            props.dispatch(getTaskById({id: param_id}));
        }

        this.state = {
            show: false,
            currentTask: this.initCurrentTask(currentTask),
            param_id,
            isNew,

            checkList: [],
            checkListShow: false,
        }
    }

    componentDidMount() {
        const {currentTask} = this.state;
        if(!currentTask || !currentTask.id) return false;
        
        this.props.dispatch(getCheckList({
            task_id: this.state.currentTask.id
        }));
    }

    componentDidUpdate(prevProps, prevState) {
        const {tasks, match:{params}, checkList} = this.props;
        const {isNew, param_id, currentTask} = this.state;

        let isTaking = prevProps.tasks.isTaking !== tasks.isTaking && !tasks.isTaking;
        let isFetching = prevProps.tasks.isFetching !== tasks.isFetching && !tasks.isFetching

        if((isFetching || isTaking) && !isNew) {
            if(tasks.errors) {
                this.setState({doesNotExits: true});
            }
            else {
                let currentTask = tasks.data[param_id];
                this.setState({
                    currentTask: this.initCurrentTask(currentTask),
                });
            }
        }

        if(!isSimilarObject(prevProps.match.params, params)) {
            this.setState({
                isNew: (params.id === 'new'),
                doesNotExits: false,
            })
        }

        if(
            (prevProps.checkList.isFetching !== checkList.isFetching && !checkList.isFetching) ||
            (prevProps.checkList.isCreating !== checkList.isCreating && !checkList.isCreating)
        ) {
            let array = _.values(checkList.data);
            let newArray = array.filter(item => item.task === currentTask.id);

            this.setState({checkList: newArray});
        }
    }

    initCurrentTask(state) {
        let newState = Object.assign({}, state);

        let plan_start_at = UTCDateTimeToLocal(newState.plan_start_at);
        let plan_end_at = UTCDateTimeToLocal(newState.plan_end_at);

        if(plan_start_at) {
            plan_start_at = `${dateTimeToDayName(plan_start_at)} в ${moment(plan_start_at).format('HH:mm')}`;
        }

        if(plan_end_at) {
            plan_end_at = `${dateTimeToDayName(plan_end_at)} в ${moment(plan_end_at).format('HH:mm')}`;
        }

        return {
            title: '',
            content: '',
            estimate_hours: 0,
            milestone: null,
            milestone_object: null,
            project: null,
            contractor: null,
            reopen_count: 0,
            spent_hours: 0,
            state: "OPEN",

            ...newState,

            plan_end_at,
            plan_start_at,
        };
    }

    handleOnChange(field, value) {
        this.setState({
            currentTask: {
                ...this.state.currentTask,
                [field]: value,
            }
        })
    }

    goToNewTask = () => {
        this.props.history.replace("/tasks/new");
    }

    handleActions = (action, params) => {
        const {currentTask} = this.state;
        const {dispatch, history} = this.props;

        if(action === 'edit') history.push(`/tasks/edit/${currentTask.id}`, currentTask);
        else if(action === 'start') dispatch(startDevelopTask(currentTask));
        else if(action === 'stop') dispatch(stopDevelopTask(currentTask));
        else if(action === 'close') dispatch(closeTask(currentTask));
        else if(action === 'reopen') dispatch(reopenTask(currentTask));
        else if(action === 'finish') dispatch(finishDevelopTask(currentTask));
        else if(action === 'estimate_show') this.handleShow();
        else if(action === 'estimate') {
            dispatch(estimateTask({
                ...currentTask,
                ...params
            }));
        }
    }

    handleClose = () => {
        this.setState({ show: false });
    }
  
    handleShow = () => {
        this.setState({ show: true });
    }

    handleAddItem = (item) => {
        if(!this.state.currentTask) return false;

        this.props.dispatch(createCheckList({
            task: this.state.currentTask.id,
            ...item,
        }));
    }

    handleUpdateItem = (item) => {
        if(!this.state.currentTask) return false;
        
        let params = {
            task: this.state.currentTask.id,
            content: item.content,
        }

        this.props.dispatch(updateCheckList(params, item.id));
    }

    setStateCheckList = (item, type) => {
        if(!this.state.currentTask || !item.state || !type) return false;

        let params = {
            task: this.state.currentTask.id,
            state: item.state,
        }

        this.props.dispatch(setStateCheckList(params, item.id, type));
    }

    handleRemoveItem = (item) => {
        if(!this.state.currentTask) return false;

        this.props.dispatch(deleteCheckList(item));
    }
    
    render() {
        const {tasks, projects, users, taskProcess} = this.props;
        const { currentTask, checkList } = this.state;

        let taskTitle = 'Название задачи не указано';
        if(currentTask.id) {
            taskTitle = `${currentTask.title} №${currentTask.id}`;
        }

        if (this.state.doesNotExits) {
            return  <DoesNotExists 
                        errorMessage={tasks.errors.detail}
                        onPress={this.goToNewTask}
                    />
        }

        const projectOptions = Object.keys(projects.data).map(key => {
            let item = projects.data[key];
            return {value: item.id, label: item.title}
        });

        const userOptions = Object.keys(users.data).map(key => {
            let item = users.data[key];
            return {value: item.id, label: `${item.first_name} ${item.last_name}`}
        });
        
        const project = projectOptions.filter(item => item.value === currentTask.project)[0];
        const contractor = userOptions.filter(item => item.value === currentTask.contractor)[0];
        const owner = users.data[currentTask.owner];

        let taskStateObject = taskStates[currentTask.state];
        let created_at = UTCDateTimeToLocal(currentTask.created_at);
        // console.log("created_at", currentTask.created_at, created_at)

        return (
            <div>
                <Header />

                <EstimateModal
                    show={this.state.show}
                    handleClose={this.handleClose}
                    handleSubmit={(params) => this.handleActions('estimate', params)}
                    taskProcess={taskProcess[currentTask.id] || {}}
                />

                <Container className="general-container">
                    <PageTitle title={taskTitle}>
                        <Button
                            variant="primary"
                            size="md"
                            onClick={this.goToNewTask}>
                            Новая задача
                        </Button>
                    </PageTitle>

                    <div className="line"/>

                    <TaskHeaderInfo 
                        owner={owner}
                        created_at={created_at}
                        currentTask={currentTask}
                        taskStateObject={taskStateObject}
                    />

                    <Row>
                        <Col xs={12}>
                            <TaskDescription 
                                project={project && project.label}
                                content={currentTask.content}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={4} style={{marginTop: '15px'}}>
                            <TaskViewRightInfoBlock
                                task={currentTask}
                                contractor={(contractor && contractor.label) || ''}
                                handleActions={this.handleActions}
                            />
                        </Col>
                        <Col xs={8} style={{marginTop: '15px'}}>
                            {
                                (!checkList.length) ? null :
                                <CheckList
                                    show={true}
                                    viewMode={true} //!currentTask.can_edit
                                    list={checkList}
                                    handleAddItem={this.handleAddItem}
                                    handleRemoveItem={this.handleRemoveItem}
                                    handleUpdateItem={this.handleUpdateItem}
                                    setStateCheckList={this.setStateCheckList}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        tasks: state.tasks,
        projects: state.projects,
        sessions: state.sessions,
        checkList: state.checkList,
        taskProcess: state.taskProcess,
    }
}

export default connect(mapStateToProps)(TaskSingleView)