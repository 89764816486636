import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

export default class NoMatch extends Component {
    render() {
        return <div>
            <p>404 страница не найдена</p>
            <NavLink to="/">На главную</NavLink>
        </div>
    }
}