import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ListItem extends Component {

    render() {
        const {item} = this.props;
        if(!item || item.developer_spent === 0) return false;

        return (
            <tr>
                <td>{item.id}</td>
                <td className="td-status">
                    {`${item.first_name} ${item.last_name}`}
                </td>
                <td>
                    {item.developer_spent} ч.
                </td>
            </tr>
        )
    }
}

ListItem.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    item: PropTypes.object.isRequired,
};