import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import { isSimilarObject } from '../../../../utils/helpers';
import { taskStates } from '../../../../utils/Constants';

export default class DataTable extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        let { data, onPress, deleteMilestone } = this.props;

        return (
            <Table className="task-table" style={{marginTop:'15px'}} striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Статус</th>
                        <th>Название этапа</th>
                        <th>План начала</th>
                        <th>План конца</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((item, index) => {
                            let itemState = Object.assign({}, taskStates[item.state]);
                            
                            return  <ListItem
                                        key={index}
                                        item={item}
                                        itemState={itemState}
                                        onPress={onPress}
                                        deleteMilestone={deleteMilestone}
                                    />
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

DataTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    data: PropTypes.array.isRequired,
    onPress: PropTypes.func.isRequired,
};