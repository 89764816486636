import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import FinanceContainer from './FinanceContainer';
import { getUser } from '../../actions/userActions';

class FinanceMain extends Component {

    componentDidMount() {
        const { users } = this.props;

        if(users.count !== users.ids.length) {
            this.props.dispatch(getUser());
        }
    }

    render() {

        return (
            <Switch>
                <Route exact path="/finance" component={FinanceContainer} />
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
    }
}

export default connect(mapStateToProps)(FinanceMain)