import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function reducer(state = initialState.sessions, action) {
    switch (action.type) {
        case types.LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                authorize: false,
            });
        case types.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                token: action.payload,
                authorize: true,
            });
        case types.LOGIN_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
                authorize: false,
            });

        
        case types.AUTH_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.AUTH_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                loggedIn: true,
                data: action.payload,
            });
        case types.AUTH_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
                loggedIn: false,
            });
        
        default:
            return state;
    }
}