import BaseApi from './base_api';
import { API } from '../utils/Constants';
import { getHeader } from '../utils/helpers'


class Api extends BaseApi {

  axiosOverride = axios => {
    axios.defaults.headers.common = getHeader()
    return axios
  };

  getProject = async ({ id }) => {
    const result = await this.get(`project/${id}/`);
    return result
  }

  getProjects = async ({ title, state }) => {
    const result = await this.get('project/', {
      page_size: 99,
      title__icontains: title,
      state
    });
    return result
  }

  archiveProject = async ({ id }) => {
    const result = await this.post(`project/${id}/archive/`)
    return result
  }

  // updateProject = async ({ id, state }) => {
  //   const result = await this.patch(`project/${id}/`, {
  //     state
  //   })
  //   return result
  // }

  getFinanceProjects = async ({ 
    page = 1, 
    page_size = 99, 
    from_plan_end_at, 
    to_plan_end_at, 
    from_paid_at,
    to_paid_at
  } = {}) => {
    const result = await this.get('project_finance/', {
      page_size,
      page,
      project_milestones__plan_end_at__gte: from_plan_end_at,
      project_milestones__plan_end_at__lte: to_plan_end_at,
      project_milestones__paid_at__gte: from_paid_at,
      project_milestones__paid_at__lte: to_paid_at
    });
    return result
  }

  updateMilestone = async ({
    id,
    plan_end_at,
    to_plan_end_at,
    paid_at,
    to_paid_at,
    price_paid,
  } = {}) => {
    const result = await this.patch(`milestone/${id}/`, {
      plan_end_at,
      to_plan_end_at,
      paid_at,
      to_paid_at,
      price_paid,
    });
    return result
  }

  deleteMilestone = async ({ id } = {}) => {
    const result = await this.delete(`milestone/${id}/`, {})
    return result
  }
}

const api = new Api(API);

export default api