import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';

export default class PageTitle extends Component {
    shouldComponentUpdate(nextProps) {
        if(
            this.props.title === nextProps.title &&
            this.props.reRender === nextProps.reRender
        ) {
            return false;
        }
        return true;
    }

    render() {
        const {title} = this.props;

        return (
            <Row>
                <Col className="page-title col-inline">
                    <h2>{title}</h2>
                    {this.props.children}
                </Col>
            </Row>
        )
    }
}