import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import ProjectsMain from './containers/Projects';
import FinanceMain from './containers/Finance';
import TasksMain from './containers/Tasks';
import SignInContainer from './containers/Auth/SignInContainer';
import ProtectedRoute from './components/Commons/ProtectedRoute';
import NoMatch from './components/Commons/NoMatch';
import { authenticate } from './actions/sessionActions';
import { getToken } from './utils/helpers';


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }
    }

  componentDidMount() {
        let token = getToken();

        if(token) {
            this.props.dispatch(authenticate());
        }
        else {
            this.setState({loading: false});
        }
    }

    componentDidUpdate(prevProps) {
        const {sessions} = this.props;

        if(
            this.state.loading && 
            prevProps.sessions.isFetching !== sessions.isFetching &&  
            !sessions.isFetching
        ) {
            this.setState({loading: false});
        }
    }

    render() {
        const {sessions} = this.props;
        if(this.state.loading) return <p>Загружается...</p>

        return (
            <BrowserRouter basename='/'>
                <Switch>
                    <Redirect exact from='/' to="/tasks" />
                    <Route path="/login" component={SignInContainer} />
                    <ProtectedRoute path="/tasks" sessions={sessions} component={TasksMain} />
                    <ProtectedRoute path="/projects" sessions={sessions} component={ProjectsMain} />
                    <ProtectedRoute path="/finance" sessions={sessions} component={FinanceMain} />
                    <Route component={NoMatch}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        sessions: state.sessions,
    }
}

export default connect(mapStateToProps)(App);