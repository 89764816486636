import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

export default class ProjectsTable extends Component {

    render() {
        const { projects } = this.props;

        return (
            <Table className="task-table" striped bordered hover>
                <thead>
                    <tr>
                        <th>Проект</th>
                        <th>Ответственный</th>
                        <th>Этап</th>
                        <th>Планируемая дата платежа</th>
                        <th>Фактическая дата платежа</th>
                        <th>Сумма, руб.</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (projects || []).map((item, index) => {
                            let contractor = item.contractor_object;
                            return (
                                <ListItem
                                    key={index}
                                    item={item}
                                    contractor={contractor} 
                                />
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

ProjectsTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    projects: PropTypes.array.isRequired,
};