import axios from 'axios';
import * as types from './actionTypes';
import { API } from '../utils/Constants';
import { getHeader } from '../utils/helpers';

function getUserSuccess(payload) {
    return {
        type: types.LOAD_USER_SUCCESS,
        payload,
    }
}

function getUserFailure(errors) {
    return {
        type: types.LOAD_USER_FAILURE,
        errors
    }
}

export function getUser(args) {
    return function(dispatch) {
        let params = Object.assign({}, {
            page_size: 9999
        }, args);
        
        dispatch({type: types.LOAD_USER_REQUEST});

        return axios.get(
            `${API}user/`,
            {
                params,
                headers: getHeader()
            }
        )
        .then(response => {
            dispatch(getUserSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getUserFailure error', errors.data)
            dispatch(getUserFailure(errors.data || {}));
        });
    }
}
