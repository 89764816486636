import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { isSimilarObject, myToString } from '../../../utils/helpers';

export default class TaskDescription extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            project,
            content,
        } = this.props;

        return (
            <Card>
                <Card.Body>
                    <Card.Title>{`Проект ${project || ''}`}</Card.Title>
                    {
                        (myToString(content) === '' || myToString(content) === '<p></p>' || myToString(content) === '<p></p>\n')
                        ? <Card.Text>Без описания</Card.Text>
                        : <div dangerouslySetInnerHTML={{__html: content}} />
                    }
                </Card.Body>
            </Card>
        )
    }
}

TaskDescription.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    project: PropTypes.any,
    content: PropTypes.any.isRequired,
};