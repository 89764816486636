import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import TaskView from './TaskView';
import TaskEdit from './TaskEdit';
import TasksContainer from './TasksContainer';
import { getProject } from '../../actions/projectActions';
import { getUser } from '../../actions/userActions';

class TasksMain extends Component {

    componentDidMount() {
        const { projects, users } = this.props;

        if(projects.count !== projects.ids.length || projects.next) {
            if(projects.next) {
                this.props.dispatch(getProject({url: projects.next}));
            }
            else this.props.dispatch(getProject());
        }

        if(users.count !== users.ids.length || users.next) {
            if(users.next) {
                this.props.dispatch(getUser({url: users.next}));
            }
            else this.props.dispatch(getUser());
        }
    }

    render() {

        return (
            <Switch>
                <Route exact path="/tasks" component={TasksContainer} />
                <Route path="/tasks/edit/:id?" component={TaskEdit} />
                <Route path="/tasks/new" component={TaskEdit} />
                <Route path="/tasks/:id?" component={TaskView} />
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        projects: state.projects,
    }
}

export default connect(mapStateToProps)(TasksMain)