import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import ProjectsContainer from './ProjectsContainer';
import ProjectView from './ProjectView';
import MilestoneEdit from '../Milestones/MilestoneEdit';
import { getProject } from '../../actions/projectActions';
import { getUser } from '../../actions/userActions';

class ProjectsMain extends Component {

    componentDidMount() {
        const { projects, users } = this.props;

        if(projects.count !== projects.ids.length || projects.next) {
            if(projects.next) {
                this.props.dispatch(getProject({url: projects.next}));
            }
            else this.props.dispatch(getProject());
        }

        if(users.count !== users.ids.length) {
            this.props.dispatch(getUser());
        }
    }

    render() {

        return (
            <Switch>
                <Route exact path="/projects" component={ProjectsContainer} />
                <Route exact path="/projects/:id?" component={ProjectView} />
                <Route path="/projects/milestone/edit/:id?" component={MilestoneEdit} />
                <Route path="/projects/milestone/new" component={MilestoneEdit} />
            </Switch>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        projects: state.projects,
    }
}

export default connect(mapStateToProps)(ProjectsMain)