import React, { Component } from 'react';
import PropsTypes from 'prop-types';
import { Form, ButtonToolbar, Button } from 'react-bootstrap';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { myToString } from '../../../../utils/helpers';

export default class TextEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            buttonDisable: true,
            content: props.content2.content,
            status: props.content2.state,
            id: props.content2.id,
        }
    }

    componentDidMount() {
        this.setButtonState(this.state.content);
    }

    componentDidUpdate(prevProps, prevState) {
        const {content} = this.state;
        const {content2} = this.props;
        const sc1 = prevState.content.getCurrentContent().getPlainText();
        const sc2 = content.getCurrentContent().getPlainText();

        const markup = this.getMarkup(content);
        const markup2 = this.getMarkup(prevState.content);

        const pc1 = content2.content.getCurrentContent().getPlainText();
        const pc2 = prevProps.content2.content.getCurrentContent().getPlainText();

        if(
            (myToString(sc1) !== myToString(sc2)) ||
            (markup2 || '').indexOf('<img') !== (markup || '').indexOf('<img')
        ) {
            this.setButtonState(this.state.content);
        }

        if(myToString(pc1) !== myToString(pc2)) {
            this.setState({
                content: content2.content,
                status: content2.state,
                id: content2.id,
            });
        }
    }

    getMarkup(content) {
        const rawContentState = convertToRaw(content.getCurrentContent());
        return draftToHtml(rawContentState);
    }

    setButtonState(value) {
        let state = this.checkForSomeEmptyInput(value);
        this.setState({buttonDisable: state});
    }

    checkForSomeEmptyInput(value) {
        let state = false;
        const rawContentState = convertToRaw(value.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        if(!markup || markup === '<p></p>' || markup === '<p></p>\n') {
            state = true;
        }
        return state;
    }

    handleContent = (content) => {
        this.setState({content});
    }

    addNewItem = () => {
        const rawContentState = convertToRaw(this.state.content.getCurrentContent());
        const markup = draftToHtml(rawContentState);

        this.props.addItem({
            content: markup,
            state: this.state.status,
            id: this.state.id,
        });

        this.setState({
            status: "OPEN",
            content: EditorState.createEmpty(),
            id: null,
        });
    }

    onCancel = () => {
        this.setState({
            status: "OPEN",
            content: EditorState.createEmpty(),
            id: null,
        });
        this.props.onCancel();
    }

    getDefaultContent(content) {
        if(myToString(content) === '' || content === '<p></p>' || content === '<p></p>\n') {
            return EditorState.createEmpty();
        }
       
        return EditorState.createWithContent(
            ContentState.createFromBlockArray(
                convertFromHTML(content)
            )
        );
    }

    getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        // Since FileReader is asynchronous,
        // we need to pass data back.
        reader.onload = () => callback(reader.result);
        // TODO: catch an error
        reader.onerror = error => {};
    };
      
    imageUploadCallback = file => new Promise(
        (resolve, reject) => this.getFileBase64(
            file,
            data => {
                resolve({ data: { link: data } })
            }
        )
    );

    render() {
        return (
            <div>
                <Form.Group>
                    <Editor
                        editorState={this.state.content}
                        wrapperClassName="text-editor__wrapper"
                        editorClassName="text-editor__body"
                        placeholder="Введите описание"
                        onEditorStateChange={this.handleContent}
                        ref={this.wysiwyg}
                        toolbar={{
                            image: {
                                urlEnabled: false,
                                uploadEnabled: true,
                                previewImage: true,
                                uploadCallback: this.imageUploadCallback
                            }
                        }}
                    />
                </Form.Group>
                
                <ButtonToolbar>
                    <Button variant="primary" disabled={this.state.buttonDisable} onClick={this.addNewItem}>
                        {this.state.id ? 'Обновить' : 'Добавить'}
                    </Button>
                    <Button variant="link" onClick={this.onCancel}>Отменить</Button>
                </ButtonToolbar>
            </div>
        )
    }
}

TextEditor.propTypes = {
    content2: PropsTypes.object.isRequired,
    addItem: PropsTypes.func.isRequired,
    onCancel: PropsTypes.func.isRequired,
}