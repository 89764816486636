import React, { Component } from 'react';
import $ from 'jquery'
import dt from 'datatables.net-dt'
import { connect } from 'react-redux';
// import resp from 'datatables.net-responsive-dt'
// import s from 'datatables.net-responsive-dt'
// import test from 'datatables.net-scroller-dt'
import './styles/style.css'
import './styles/asd.css'
import { getTask } from '../../actions/taskActions';

    let idStores,
        status,
        butContent,
        opis

      // $.DataTable = require('datatables.net' );

      const columns = [
        {
          title: '#',
          width:"25%",
        },
        {
          title: 'Статус',
          width:300, 
        },
        {
            title: 'Действие',
            width:200,       
          },
          {
            title: 'Описание',
            width:200,
           
          }
       
      ];


      class TasksMain extends Component {
        constructor(props){
          super(props)
          this.state = props
        }
        componentDidMount() {   
         this.props.dispatch(getTask({}));    
          
         console.log(this.state);
         
          $('#data___table').DataTable({
            dom: '<"fg-toolbar ui-toolbar ui-widget-header ui-helper-clearfix ui-corner-tl ui-corner-tr"lfr>'+
            't'+
            '<"fg-toolbar ui-toolbar ui-widget-header ui-helper-clearfix ui-corner-bl ui-corner-br"ip>',
            
            "data":[
            [<p>state.id</p>,"<button>qwe</button>", "<p>qwe</p>" ,'<p>qwe</p>' ],
            ['<p>qwe</p>',"<button>qwe</button>", "<p>qwe</p>" ,'<p>qwe</p>' ],
            ['<p>qwe</p>',"<button>qwe</button>", "<p>qwe</p>" ,'<p>qwe</p>' ]
            ],
            columns,
            ordering: false,
            responsive: true
            

          })
        }
        shouldComponentUpdate(nextProps) {
            return true;
          console.log(nextProps);
          
        }
      
        render() {
            
          const {tasks} = this.props;
        // console.log(tasks);
           
          return (
            <div>
              <table ref="main" id="data___table">
             
              </table>
            </div>
          )
        }
      }



    
      const mapStateToProps = state => {
        return {
            tasks: state.tasks,
        }
      }
export default connect(mapStateToProps)(TasksMain)