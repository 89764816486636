import { combineReducers } from "redux";
import users from "./userReducer";
import projects, {projectProcess, timeTrack} from "./projectReducer";
import sessions from "./sessionsReducer";
import tasks, {taskProcess} from "./taskReducer";
import milestones, {milestoneProcess} from "./milestoneReducer";
import checkList from "./checkListReducer";

export default combineReducers({
    users,
    tasks,
    projects,
    sessions,
    timeTrack,
    checkList,
    milestones,
    taskProcess,
    projectProcess,
    milestoneProcess,
});