import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import api from '../../api'
// import { colourOptions } from '../data';

const loadOptions = (inputValue, callback) => {
  api.getProjects({
    title: inputValue,
    state: 'NEW'
  }).then(response => {
    const { results } = response.data
    const options = results.map(item => {
      return {
        value: item.id,
        label: item.title
      }
    })
    callback(options)
  })
};

export default class ProjectSelect extends Component {
  state = { inputValue: '' };

  handleInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  componentDidUpdate(prevProps) {
    const nextValue = this.props.value
    const propsValue = prevProps.value
    if(nextValue && propsValue && nextValue.label !== propsValue.label) {
      this.handleInputChange(nextValue)
    }
  }

  render() {
    const {
      label
    } = this.props
    return (
      <>
        {label !== undefined ? <label className="form-label">{label}</label> : null}
        <AsyncSelect
          className="select-custom"
          placeholder="Все проекты"
          cacheOptions
          loadOptions={loadOptions}
          defaultOptions
          onInputChange={this.handleInputChange}
          {...this.props}
        />
      </>
    );
  }
}