import React, { Component } from 'react';
import {Badge} from 'react-bootstrap';
import moment from 'moment';
import PropTypes from 'prop-types';

export default class ListItem extends Component {

    render() {
        const {item, onPress, itemState, deleteMilestone} = this.props;
        if(!item) return false;

        return (
            <tr>
                <td onClick={() => onPress(item)}>{item.id}</td>
                <td onClick={() => onPress(item)} className="td-status">
                    <Badge variant={itemState.color} title={itemState.title}>
                        {itemState.shortTitle || itemState.title}
                    </Badge>
                </td> 
                {/* <td>
                    <EstimateBadge data={{
                        spent_hours: item.developer_spent,
                        estimate_hours: item.developer_estimate
                    }}/>
                </td> */}
                <td onClick={() => onPress(item)}>{item.title}</td>
                <td>{item.plan_start_at ? moment(item.plan_start_at).format("DD-MM-YYYY") : 'Не указана'}</td>
                <td>{item.plan_end_at ? moment(item.plan_end_at).format("DD-MM-YYYY") : 'Не указана'}</td>
                <td>
                    <button onClick={() => deleteMilestone(item.id)} className="btn btn-danger" type="button">Удалить</button>
                </td>
            </tr>
        )
    }
}

ListItem.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    onPress: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
};