import React, {Component} from 'react';
import {Alert} from 'react-bootstrap'
import { isSimilarObject } from '../../utils/helpers';

export default class ErrorStroke extends Component {
    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {error} = this.props;
        if(!error) return null;
        let newState = Object.assign([], error);
        if(!newState.length) return null;

        return (
            <Alert variant="danger">
                {
                    newState.map(item => item + '\n')
                }
            </Alert>
        )
    }
}