import React, { Component } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import PropTypes from 'prop-types';

import ProjectSelect from '../../Projects/ProjectSelect'

import { isSimilarObject } from '../../../utils/helpers';
import { taskStates } from '../../../utils/Constants';

export default class TaskFilter extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {
            count,
            filters,
            userOptions,
            onOpenSelect,
            // projectOptions,
            currentProject,
            handleFilters,
            handleClear=() => {}
        } = this.props;

        let contractorOptions = [
            {value: null, label: 'Исполнитель'},
            ...userOptions,
        ]

        let creatorOptions = [
            {value: null, label: 'Постановщик'},
            ...userOptions,
        ]

        let statusOptions = [
            {value: null, label: 'Все статусы'},
            ...Object.keys(taskStates).map(key => {
                return {value: key, label: taskStates[key].title}
            })
        ]

        const currentState = statusOptions.filter(item => item.value === filters.state)[0];
        const currentOwner = creatorOptions.filter(item => item.value === filters.owner_id)[0];
        const currentContractor = userOptions.filter(item => item.value === filters.contractor_id)[0];
        return (
            <Row>
                <Col
                    className="col-params col-inline task-filter">
                    <Row className="task-filter__fields">                        
                        <Col xs={2} className="task-filter__col">
                            <Select
                                options={statusOptions}
                                className="select-custom"
                                placeholder="Все статусы"
                                value={currentState}
                                noOptionsMessage={() => "Ничего не найдено"}
                                onChange={item => handleFilters('state', item.value)}
                            />
                        </Col>
                        
                        <Col xs={2} className="task-filter__col">
                            <ProjectSelect
                                value={currentProject}
                                onMenuOpen={() => onOpenSelect('project_id')}
                                onChange={item => handleFilters('project_id', item.value)}
                            />
                            {/* <Select
                                options={projectOptions}
                                className="select-custom"
                                placeholder="Все проекты"
                                value={currentProject}
                                noOptionsMessage={() => "Ничего не найдено"}
                                onMenuOpen={() => onOpenSelect('project_id')}
                                onChange={item => handleFilters('project_id', item.value)}
                            /> */}
                        </Col>

                        <Col xs={2} className="task-filter__col">
                            <Select
                                options={creatorOptions}
                                className="select-custom"
                                placeholder="Постановщик"
                                value={currentOwner}
                                noOptionsMessage={() => "Ничего не найдено"}
                                onMenuOpen={() => onOpenSelect('owner_id')}
                                onChange={item => handleFilters('owner_id', item.value)}
                            />
                        </Col>

                        <Col xs={2} className="task-filter__col">
                            <Select
                                options={contractorOptions}
                                className="select-custom"
                                placeholder="Исполнитель"
                                value={currentContractor || null}
                                noOptionsMessage={() => "Ничего не найдено"}
                                onMenuOpen={() => onOpenSelect('contractor_id')}
                                onChange={item => handleFilters('contractor_id', item.value)}
                            />
                        </Col>

                        <Col xs={3} className="task-filter__col">
                            <Form.Control 
                                type="text"
                                value={filters.q}
                                placeholder="Поиск по ID и названию задачи"
                                onChange={e => handleFilters('q', e.target.value)}
                            />
                        </Col>
                        <Col xs={1} className="task-filter__col">
                            <Button variant="warning" size="md" onClick={handleClear}>Очистить</Button>
                        </Col>
                    </Row>
                    
                    <div>
                        <span className="tasks__count">{`Количество задач ${count}`}</span>
                    </div>
                    
                </Col>
            </Row>
        )
    }
}

TaskFilter.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    userOptions: PropTypes.array,
    currentProject: PropTypes.any,
    projectOptions: PropTypes.array,
    count: PropTypes.number.isRequired,
    filters: PropTypes.object.isRequired,
    handleFilters: PropTypes.func.isRequired,
};