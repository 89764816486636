export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const LOAD_TASK_REQUEST = 'LOAD_TASK_REQUEST';
export const LOAD_TASK_SUCCESS = 'LOAD_TASK_SUCCESS';
export const LOAD_TASK_FAILURE = 'LOAD_TASK_FAILURE';

export const LOAD_TASK_BY_ID_REQUEST = 'LOAD_TASK_BY_ID_REQUEST';
export const LOAD_TASK_BY_ID_SUCCESS = 'LOAD_TASK_BY_ID_SUCCESS';
export const LOAD_TASK_BY_ID_FAILURE = 'LOAD_TASK_BY_ID_FAILURE';

export const UPDATE_TASK_REQUEST = 'UPDATE_TASK_REQUEST';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_FAILURE = 'UPDATE_TASK_FAILURE';

export const CREATE_TASK_REQUEST = 'CREATE_TASK_REQUEST';
export const CREATE_TASK_SUCCESS = 'CREATE_TASK_SUCCESS';
export const CREATE_TASK_FAILURE = 'CREATE_TASK_FAILURE';

export const START_DEV_TASK_REQUEST = 'START_DEV_TASK_REQUEST';
export const START_DEV_TASK_SUCCESS = 'START_DEV_TASK_SUCCESS';
export const START_DEV_TASK_FAILURE = 'START_DEV_TASK_FAILURE';

export const STOP_DEV_TASK_REQUEST = 'STOP_DEV_TASK_REQUEST';
export const STOP_DEV_TASK_SUCCESS = 'STOP_DEV_TASK_SUCCESS';
export const STOP_DEV_TASK_FAILURE = 'STOP_DEV_TASK_FAILURE';

export const FINISH_DEV_TASK_REQUEST = 'FINISH_DEV_TASK_REQUEST';
export const FINISH_DEV_TASK_SUCCESS = 'FINISH_DEV_TASK_SUCCESS';
export const FINISH_DEV_TASK_FAILURE = 'FINISH_DEV_TASK_FAILURE';

export const REOPEN_TASK_REQUEST = 'REOPEN_TASK_REQUEST';
export const REOPEN_TASK_SUCCESS = 'REOPEN_TASK_SUCCESS';
export const REOPEN_TASK_FAILURE = 'REOPEN_TASK_FAILURE';

export const ESTIMATE_TASK_REQUEST = 'ESTIMATE_TASK_REQUEST';
export const ESTIMATE_TASK_SUCCESS = 'ESTIMATE_TASK_SUCCESS';
export const ESTIMATE_TASK_FAILURE = 'ESTIMATE_TASK_FAILURE';

export const CLOSE_TASK_REQUEST = 'CLOSE_TASK_REQUEST';
export const CLOSE_TASK_SUCCESS = 'CLOSE_TASK_SUCCESS';
export const CLOSE_TASK_FAILURE = 'CLOSE_TASK_FAILURE';

export const LOAD_PROJECT_REQUEST = 'LOAD_PROJECT_REQUEST';
export const LOAD_PROJECT_SUCCESS = 'LOAD_PROJECT_SUCCESS';
export const LOAD_PROJECT_FAILURE = 'LOAD_PROJECT_FAILURE';

export const LOAD_PROJECT_BY_ID_REQUEST = 'LOAD_PROJECT_BY_ID_REQUEST';
export const LOAD_PROJECT_BY_ID_SUCCESS = 'LOAD_PROJECT_BY_ID_SUCCESS';
export const LOAD_PROJECT_BY_ID_FAILURE = 'LOAD_PROJECT_BY_ID_FAILURE';

export const LOAD_TIME_TRACK_REQUEST = 'LOAD_TIME_TRACK_REQUEST';
export const LOAD_TIME_TRACK_SUCCESS = 'LOAD_TIME_TRACK_SUCCESS';
export const LOAD_TIME_TRACK_FAILURE = 'LOAD_TIME_TRACK_FAILURE';

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const LOAD_MILESTONE_REQUEST = 'LOAD_MILESTONE_REQUEST';
export const LOAD_MILESTONE_SUCCESS = 'LOAD_MILESTONE_SUCCESS';
export const LOAD_MILESTONE_FAILURE = 'LOAD_MILESTONE_FAILURE';

export const LOAD_MILESTONE_BY_ID_REQUEST = 'LOAD_MILESTONE_BY_ID_REQUEST';
export const LOAD_MILESTONE_BY_ID_SUCCESS = 'LOAD_MILESTONE_BY_ID_SUCCESS';
export const LOAD_MILESTONE_BY_ID_FAILURE = 'LOAD_MILESTONE_BY_ID_FAILURE';

export const UPDATE_MILESTONE_REQUEST = 'UPDATE_MILESTONE_REQUEST';
export const UPDATE_MILESTONE_SUCCESS = 'UPDATE_MILESTONE_SUCCESS';
export const UPDATE_MILESTONE_FAILURE = 'UPDATE_MILESTONE_FAILURE';

export const CREATE_MILESTONE_REQUEST = 'CREATE_MILESTONE_REQUEST';
export const CREATE_MILESTONE_SUCCESS = 'CREATE_MILESTONE_SUCCESS';
export const CREATE_MILESTONE_FAILURE = 'CREATE_MILESTONE_FAILURE';

export const LOAD_CHECKLIST_REQUEST = 'LOAD_CHECKLIST_REQUEST';
export const LOAD_CHECKLIST_SUCCESS = 'LOAD_CHECKLIST_SUCCESS';
export const LOAD_CHECKLIST_FAILURE = 'LOAD_CHECKLIST_FAILURE';

export const UPDATE_CHECKLIST_REQUEST = 'UPDATE_CHECKLIST_REQUEST';
export const UPDATE_CHECKLIST_SUCCESS = 'UPDATE_CHECKLIST_SUCCESS';
export const UPDATE_CHECKLIST_FAILURE = 'UPDATE_CHECKLIST_FAILURE';

export const SET_STATE_CHECKLIST_REQUEST = 'SET_STATE_CHECKLIST_REQUEST';
export const SET_STATE_CHECKLIST_SUCCESS = 'SET_STATE_CHECKLIST_SUCCESS';
export const SET_STATE_CHECKLIST_FAILURE = 'SET_STATE_CHECKLIST_FAILURE';

export const CREATE_CHECKLIST_REQUEST = 'CREATE_CHECKLIST_REQUEST';
export const CREATE_CHECKLIST_SUCCESS = 'CREATE_CHECKLIST_SUCCESS';
export const CREATE_CHECKLIST_FAILURE = 'CREATE_CHECKLIST_FAILURE';

export const DELETE_CHECKLIST_REQUEST = 'DELETE_CHECKLIST_REQUEST';
export const DELETE_CHECKLIST_SUCCESS = 'DELETE_CHECKLIST_SUCCESS';
export const DELETE_CHECKLIST_FAILURE = 'DELETE_CHECKLIST_FAILURE';

export const SET_TASK_PROCESS_VALUE = 'SET_TASK_PROCESS_VALUE';
export const SET_PROJECT_PROCESS_VALUE = 'SET_PROJECT_PROCESS_VALUE';
export const SET_CHECKLIST_PROCESS_VALUE = 'SET_CHECKLIST_PROCESS_VALUE';
export const SET_MILESTONE_PROCESS_VALUE = 'SET_MILESTONE_PROCESS_VALUE';