import axios from 'axios';
import * as types from './actionTypes';
import { API } from '../utils/Constants';
import { getHeader } from '../utils/helpers';


function getTaskSuccess(payload, moreData) {
    return {
        type: types.LOAD_TASK_SUCCESS,
        payload,
        moreData,
    }
}

function getTaskFailure(errors) {
    return {
        type: types.LOAD_TASK_FAILURE,
        errors
    }
}

export function getTask(args = {}) {
    let url = `${API}task/`;
    let moreData = args.url ? true : false;
    let params = Object.assign({}, {
        page_size: 20,
    }, args);

    if(params.url) {
        url = args.url;
        delete params.url;
    }

    return function(dispatch) {
        dispatch({type: types.LOAD_TASK_REQUEST});

        return axios.get(
            url,
            {
                params,
                headers: getHeader()
            }
        )
        .then(response => {
            dispatch(getTaskSuccess(response.data, moreData));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getTask error', errors.data)
            dispatch(getTaskFailure(errors.data || {}));
        });
    }
}



function getTaskByIdSuccess(payload) {
    return {
        type: types.LOAD_TASK_BY_ID_SUCCESS,
        payload,
    }
}

function getTaskByIdFailure(errors) {
    return {
        type: types.LOAD_TASK_BY_ID_FAILURE,
        errors
    }
}

export function getTaskById(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'get', true));
        dispatch({type: types.LOAD_TASK_BY_ID_REQUEST});

        return axios.get(
            `${API}task/${task.id}/`,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("getTaskByIdSuccess success", response.data);
            dispatch(getTaskByIdSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getTaskByIdFailure error', errors.data)
            dispatch(getTaskByIdFailure(errors.data || {}));
        })
        .then(function () {
            // always executed
            dispatch(setProcessValue(task.id, 'get', false));
        });
    }
}



function updateTaskSuccess(payload) {
    return {
        type: types.UPDATE_TASK_SUCCESS,
        payload,
    }
}

function updateTaskFailure(errors) {
    return {
        type: types.UPDATE_TASK_FAILURE,
        errors
    }
}

export function updateTask(task, id) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'update', true));
        dispatch({type: types.UPDATE_TASK_REQUEST});

        return axios.patch(
            `${API}task/${id}/`,
            task,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("updateTaskSuccess success", response.data);
            dispatch(updateTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('updateTaskFailure error', errors.data)
            dispatch(updateTaskFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(task.id, 'update', false));
        });
    }
}



function createTaskSuccess(payload) {
    return {
        type: types.CREATE_TASK_SUCCESS,
        payload,
    }
}

function createTaskFailure(errors) {
    return {
        type: types.CREATE_TASK_FAILURE,
        errors
    }
}

export function createTask(task, afterSuccess) {
    return function(dispatch) {
        
        dispatch({type: types.CREATE_TASK_REQUEST});

        return axios.post(
            `${API}task/`,
            task,
            {
                headers: getHeader()
            },
        )
        .then(response => {
            console.log("createTaskSuccess success", response.data);
            if(afterSuccess) afterSuccess(response.data)
            dispatch(createTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('createTaskFailure error', errors.data)
            dispatch(createTaskFailure(errors.data || {}));
        });
    }
}



function startDevelopTaskSuccess(payload, contractor) {
    return {
        type: types.START_DEV_TASK_SUCCESS,
        payload,
        contractor,
    }
}

function startDevelopTaskFailure(errors) {
    return {
        type: types.START_DEV_TASK_FAILURE,
        errors
    }
}

export function startDevelopTask(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'start', true));
        dispatch({type: types.START_DEV_TASK_REQUEST});

        return axios.post(
            `${API}task/${task.id}/start_develop/`,
            {
                id: task.id
            },
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("startDevelopTaskSuccess success", response.data, task.contractor);
            dispatch(startDevelopTaskSuccess(response.data, task.contractor));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('startDevelopTaskFailure error', errors.data)
            dispatch(startDevelopTaskFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(task.id, 'start', false));
        });
    }
}



function stopDevelopTaskSuccess(payload) {
    return {
        type: types.STOP_DEV_TASK_SUCCESS,
        payload,
    }
}

function stopDevelopTaskFailure(errors) {
    return {
        type: types.STOP_DEV_TASK_FAILURE,
        errors
    }
}

export function stopDevelopTask(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'stop', true));
        dispatch({type: types.STOP_DEV_TASK_REQUEST});

        return axios.post(
            `${API}task/${task.id}/stop_develop/`,
            {id: task.id},
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("stopDevelopTaskSuccess success", response.data);
            dispatch(stopDevelopTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('stopDevelopTaskFailure error', errors.data)
            dispatch(stopDevelopTaskFailure(errors.data || {}));
        })
        .then(function () {
            // always executed
            dispatch(setProcessValue(task.id, 'stop', false));
        });
    }
}


function finishDevelopTaskSuccess(payload) {
    return {
        type: types.FINISH_DEV_TASK_SUCCESS,
        payload,
    }
}

function finishDevelopTaskFailure(errors) {
    return {
        type: types.FINISH_DEV_TASK_FAILURE,
        errors
    }
}

export function finishDevelopTask(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'finish', true));
        dispatch({type: types.FINISH_DEV_TASK_REQUEST});

        return axios.post(
            `${API}task/${task.id}/finish/`,
            {id: task.id},
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("finishDevelopTaskSuccess success", response.data);
            dispatch(finishDevelopTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('finishDevelopTaskFailure error', errors.data || {});
            dispatch(finishDevelopTaskFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(task.id, 'finish', false));
        });
    }
}


function reopenTaskSuccess(payload) {
    return {
        type: types.REOPEN_TASK_SUCCESS,
        payload,
    }
}

function reopenTaskFailure(errors) {
    return {
        type: types.REOPEN_TASK_FAILURE,
        errors
    }
}

export function reopenTask(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'reopen', true));
        dispatch({type: types.REOPEN_TASK_REQUEST});

        return axios.post(
            `${API}task/${task.id}/reopen/`,
            {id: task.id},
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("reopenTaskSuccess success", response.data);
            dispatch(reopenTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('reopenTaskFailure error', errors.data || {});
            dispatch(reopenTaskFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(task.id, 'reopen', false));
        });
    }
}


function estimateTaskSuccess(payload) {
    return {
        type: types.ESTIMATE_TASK_SUCCESS,
        payload,
    }
}

function estimateTaskFailure(errors) {
    return {
        type: types.ESTIMATE_TASK_FAILURE,
        errors
    }
}

export function estimateTask(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'estimate', true));
        dispatch({type: types.ESTIMATE_TASK_REQUEST});

        return axios.post(
            `${API}task/${task.id}/estimate/`,
            {id: task.id, estimate_hours: task.estimate_hours},
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("estimateTaskSuccess success", response.data);
            dispatch(estimateTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('estimateTaskFailure error', errors.data || {});
            dispatch(estimateTaskFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(task.id, 'estimate', false));
        });
    }
}


function closeTaskSuccess(payload) {
    return {
        type: types.CLOSE_TASK_SUCCESS,
        payload,
    }
}

function closeTaskFailure(errors) {
    return {
        type: types.CLOSE_TASK_FAILURE,
        errors
    }
}

export function closeTask(task) {
    return function(dispatch) {
        
        dispatch(setProcessValue(task.id, 'close', true));
        dispatch({type: types.CLOSE_TASK_REQUEST});

        return axios.post(
            `${API}task/${task.id}/close/`,
            {id: task.id},
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("closeTaskSuccess success", response.data);
            dispatch(closeTaskSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('closeTaskFailure error', errors.data || {});
            dispatch(closeTaskFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(task.id, 'close', false));
        });
    }
}



function setProcessValue(task_id, action_name, action_state) {
    return {
        type: types.SET_TASK_PROCESS_VALUE,
        payload: {
            task_id,
            action_name,
            action_state,
        },
    }
}