import * as types from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function reducer(state = initialState.users, action) {
    switch (action.type) {
        case types.LOAD_USER_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.LOAD_USER_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    ..._.mapKeys(action.payload.results, 'id')
                },
                ids: [..._.union(state.ids, [..._.map(action.payload.results, 'id')])],
                count: action.payload.count,
                next: action.payload.next,
                timestamp: new Date(),
            });
        case types.LOAD_USER_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
            
        
        default:
            return state;
    }
}