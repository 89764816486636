import React, { Component } from 'react';
import PropsTypes from 'prop-types';
import { Card, Button, Badge } from 'react-bootstrap';
import { myToString } from '../../../../utils/helpers';
import { taskStates } from '../../../../utils/Constants';

export default class ListItem extends Component {

    render() {
        const {
            data,
            viewMode,
            editItem,
            deleteItem,
            handleOnChange,
        } = this.props;

        // can_close: false
        // can_finish: false
        // can_reopen: false
        let badgeState = taskStates[data.state] || {};

        return (
            <div className="checklist__listitem" onDoubleClick={editItem}>
                <h6 style={{marginRight:'10px'}}>
                    <Badge title={badgeState.title} variant={badgeState.color}>
                        {badgeState.shortTitle || badgeState.title}
                    </Badge>
                </h6>
                
                <div style={{display:'flex',flexDirection:'column', marginRight:'10px'}}>
                    {
                        data.can_finish ?
                        <Button 
                            size="sm"
                            variant="success"
                            onClick={() => handleOnChange('finish')}
                            >Выполнено
                        </Button>
                        : null
                    }

                    {
                        data.can_reopen ?
                        <Button 
                            size="sm"
                            variant="primary"
                            onClick={() => handleOnChange('reopen')}
                            >Переоткрыть
                        </Button>
                        : null
                    }

                    {
                        data.can_close ?
                        <Button 
                            size="sm"
                            variant="dark"
                            onClick={() => handleOnChange('close')}
                            >Закрыть
                        </Button>
                        : null
                    }
                </div>

                {
                    (myToString(data.content) === '' || myToString(data.content) === '<p></p>' || myToString(data.content) === '<p></p>\n')
                    ? <Card.Text>Без описания</Card.Text>
                    : <div dangerouslySetInnerHTML={{__html: data.content}} />
                }

                {
                    viewMode ? null :
                    <Button 
                        size="sm"
                        variant="link"
                        onClick={deleteItem}
                        className="checklist__listitem-del"
                        title="Удалить">Удалить
                    </Button>
                }
            </div>
        )
    }
}

ListItem.propTypes = {
    data: PropsTypes.object.isRequired,
    viewMode: PropsTypes.bool.isRequired,
    editItem: PropsTypes.func.isRequired,
    deleteItem: PropsTypes.func.isRequired,
    handleOnChange: PropsTypes.func.isRequired,
}