import React from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { isSimilarObject } from '../../../../utils/helpers';

export default class EstimateModal extends React.Component {
    state = {
        estimate: 0,
        buttonDisable: true,
    };

    shouldComponentUpdate(nextProps, nextState) {
        if(
            isSimilarObject(this.props, nextProps) && 
            isSimilarObject(this.state, nextState)
        ) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState) {
        const {estimate} = this.state;
        const {taskProcess} = this.props;

        if(prevState.estimate !== estimate) {
            this.setButtonState(estimate);
        }

        if(
            prevProps.taskProcess && taskProcess && 
            prevProps.taskProcess.estimate !== taskProcess.estimate
        ) {
            this.setState({buttonDisable: taskProcess.estimate || false});
            if(!taskProcess.estimate) this.handleClose();
        }
    }

    setButtonState(value) {
        let state = this.checkForSomeEmptyInput(value);
        this.setState({buttonDisable: state});
    }

    checkForSomeEmptyInput(value) {
        let state = false;
        if(!value || isNaN(parseFloat(value)) || parseFloat(value) <= 0) {
            state = true;
        }
        return state;
    }

    handleOnChange = (e) => {
        this.setState({estimate: e.target.value});
    }

    handleSubmit = () => {
        this.props.handleSubmit(
            {estimate_hours: this.state.estimate}
        );
    }

    handleClose = () => {
        this.setState({estimate: 0, buttonDisable: true});
        this.props.handleClose();
    }
  
    render() {
        const {show, handleClose} = this.props;
        const {estimate, buttonDisable} = this.state;

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Оценить задачу</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="exampleForm.ControlInput1">
                            <Form.Label>Введите количество часов на задачу</Form.Label>
                            <Form.Control
                                type="number"
                                min={0}
                                step=".1"
                                value={estimate}
                                onChange={this.handleOnChange}
                            />
                        </Form.Group>
                    </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                    Отменить
                </Button>
                <Button variant="primary" disabled={buttonDisable} onClick={this.handleSubmit}>
                    Сохранить изменения
                </Button>
                </Modal.Footer>
            </Modal>
        );
        }
  }

EstimateModal.propTypes = {
    taskProcess: PropTypes.any,
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
}