import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';
import { UTCDateTimeToLocal } from '../../../utils/helpers';

export default class TasksTable extends Component {

    render() {
        const { users, isTaking, projects, tasksList, tableStates, handleActions } = this.props;

        return (
            <Table className="task-table" striped bordered hover>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Статус</th>
                        <th>Действие</th>
                        <th>Описание</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        tasksList.map((item, index) => {
                            let contractor = users.data[item.contractor];
                            let project = projects.data[item.project];
                            let owner = users.data[item.owner];
                            let created_at = UTCDateTimeToLocal(item.created_at);
                            let itemActive = (tableStates[item.id] ? true : false);

                            return  <ListItem
                                        key={index}
                                        item={item}
                                        owner={owner}
                                        project={project}
                                        isTaking={isTaking}
                                        created_at={created_at}
                                        contractor={contractor}
                                        itemActive={itemActive}
                                        onPress={(action) => handleActions(action, item)} />
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

TasksTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    users: PropTypes.object,
    isTaking: PropTypes.bool,
    tasksList: PropTypes.array.isRequired,
    handleActions: PropTypes.func.isRequired,
};