export let URL = '';

if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
    URL = 'http://127.0.0.1:8000/garpix_workflow/';//http://80.93.182.147:8000/garpix_workflow/
} else {
    URL = `https://garpix.com/garpix_workflow/`;
}

export const API = `${URL}api/v1/`;

export const taskStates = {
    'NEW': {title: 'Новая', shortTitle: 'Новая', color: 'light'},
    'OPEN': {title: 'Открыта для выполнения', shortTitle: 'Открытая', color: 'primary'},
    'PARTIALLY': {title: 'Частично выполнена', color: 'secondary'},
    'DEVELOP': {title: 'Выполняется', color: 'warning'},
    'FINISH': {title: 'На проверке', color: 'success'},
    'CANCEL': {title: 'Отменена', color: 'danger'},
    'CLOSE': {title: 'Закрытая', color: 'dark'},
}

export const projectStates = {
    'NEW': {title: 'Новый', shortTitle: 'Новый', color: 'primary'},
    'ACTIVE': {title: 'Активен', shortTitle: 'Активен', color: 'success'},
    'CLOSED': {title: 'Помещен в архив', shortTitle: 'Архив', color: 'secondary'},
}

export const milestoneStates = {
    'OPEN': {title: 'Открыт', color: 'primary'},
    'CLOSE': {title: 'Закрыт', color: 'danger'},
}
