import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import Header from '../../components/Commons/Header';
import ProjectsTable from '../../components/Projects/ProjectsTable';
import PageTitle from '../../components/Commons/PageTitle';
import LoadMore from '../../components/Commons/LoadMore';
import { getProject } from '../../actions/projectActions';

class ProjectsContainer extends Component {

    fetchingMore = () => {
        const {projects} = this.props;

        if(!projects.next || projects.isFetching) {
            return false;
        }
        
        this.props.dispatch(getProject({url: projects.next}));
    }

    handleActions = (action, project) => {
        const {history} = this.props;

        if(action === 'show') {
            history.push(`/projects/${project.id}`, project);
        }
    }

    render() {
        const {projects, users} = this.props;

        return (
            <div>
                <Header />

                <Container className="general-container">

                    <PageTitle title="Проекты" />

                    <Row>
                        <Col>
                            <ProjectsTable
                                users={users}
                                projects={projects}
                                handleActions={this.handleActions}
                                />
                        </Col>
                    </Row>

                    <LoadMore
                        next={projects.next}
                        isFetching={projects.isFetching}
                        onPress={this.fetchingMore} />
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        projects: state.projects,
    }
}

export default connect(mapStateToProps)(ProjectsContainer)