import axios from "axios";
import * as constants from './constants';
import paginationSerializer from './serializers/paginationSerializer';
import {getLimitOffset} from './utils';

const AUTH_TOKEN_KEY = 'auth_token';

class BaseApi {
    constructor(url) {
        this.url = url;
        this.getAuthToken = () => {
            return localStorage.getItem(AUTH_TOKEN_KEY);
        };
        this.setAuthToken = (token) => {
            localStorage.setItem(AUTH_TOKEN_KEY, token);
            return true;
        };
        this.removeAuthToken = () => {
            try {
                localStorage.removeItem(AUTH_TOKEN_KEY);
                return true
            } catch {
                return false; // no key
            }
        };
    }

    axiosOverride = axios => {
        const Token = this.getAuthToken();
        axios.defaults.headers.common['Authorization'] = `Token ${Token}`;
        return axios
    };

    post = async (url, params, axiosParams={}) => {
        return await this.send(url, 'post', params, axiosParams);
    };

    get = async (url, params, axiosParams={}) => {
        const base_url = this.url;
        const newAxios = this.axiosOverride(axios);
        const res = await newAxios({
            method: 'get',
            params: params,
            url: `${base_url}${url}`,
            ...axiosParams
        });
        return res;
    };

    put = async (url, params, axiosParams={}) => {
        return await this.send(url, 'put', params, axiosParams);
    };

    patch = async (url, params, axiosParams={}) => {
        return await this.send(url, 'patch', params, axiosParams);
    };

    delete = async (url, params, axiosParams={}) => {
        return await this.send(url, 'delete', params, axiosParams);
    };

    send = async (url, method, params, axiosParams={}) => {
        const base_url = this.url;
        const newAxios = this.axiosOverride(axios);
        const res = await newAxios({
            method: method,
            data: params,
            url: `${base_url}${url}`,
            ...axiosParams
        });
        return res;
    }
}

export default BaseApi;

export {
    constants,
    paginationSerializer,
    getLimitOffset,
};
