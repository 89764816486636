import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isSimilarObject } from '../../utils/helpers';

export default class LoadMore extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const {next, isFetching, onPress} = this.props;
        if(!next) return false;

        return (
            <Row>
                <Col style={{display: 'flex', justifyContent:'center', marginBottom: '15px'}}>
                    <Button variant="primary" size="sm" disabled={isFetching} onClick={() => onPress(next)}>
                        {isFetching ? 'Загружается...' : 'Загрузить еще'}
                    </Button>
                </Col>
            </Row>
        )
    }
}

LoadMore.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    next: PropTypes.any,
    isFetching: PropTypes.any,
    onPress: PropTypes.func.isRequired,
};