import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';

import ListItem from './ListItem';

export default class ProjectsTable extends Component {

    render() {
        const { users, projects, handleActions } = this.props;

        return (
            <Table className="task-table" striped bordered hover>
                <thead>
                    <tr>
                        <th>Проект</th>
                        <th>Руководитель проекта</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        Object.keys(projects.data).map((key, index) => {
                            let item = projects.data[key];
                            let contractor = users.data[item.contractor];
                            
                            return  <ListItem
                                        key={index}
                                        item={item}
                                        contractor={contractor}
                                        onPress={(action) => handleActions(action, item)} />
                        })
                    }
                </tbody>
            </Table>
        )
    }
}

ProjectsTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    users: PropTypes.object,
    projects: PropTypes.object,
    handleActions: PropTypes.func.isRequired,
};