import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import Header from '../../components/Commons/Header';
import PageTitle from '../../components/Commons/PageTitle';
import TaskFilter from '../../components/Task/TaskMain/TaskFilter';
import TaskTable from '../../components/Task/TaskMain/TaskTable';
import LoadMore from '../../components/Commons/LoadMore';
import { getTask, startDevelopTask, stopDevelopTask, closeTask, reopenTask, finishDevelopTask, estimateTask } from '../../actions/taskActions';
import { isSimilarObject } from '../../utils/helpers';
import EstimateModal from '../../components/Task/Modals/EstimateModal';
import { getProject } from '../../actions/projectActions';
import { getUser } from '../../actions/userActions';
import PeopleAreNotWorking from '../../components/Task/TaskMain/PeopleAreNotWorking';

class TasksContainer extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            currentTask: {},

            taskCount: props.tasks.count,
            tasksList: this.sortObjectList(props.tasks.data),
            height: window.innerHeight,
            scrollOnBottom: false,

            filters: {
                q: '',
                project_id: null,
                contractor_id: null,
                state: null,
                owner_id: null,
            },

            table: {},
        };
    }

    componentDidMount() {
        let newParams = this.parseUrl();
        let mergeParams = {...this.state.filters, ...newParams};
        this.setState({filters: mergeParams});

        this.props.dispatch(getTask(mergeParams));
    }

    componentDidUpdate(prevProps, prevState) {
        const {tasks} = this.props;
        const {filters} = this.state;

        if(
            !isSimilarObject(prevState.filters, filters) &&
            (!tasks.isFetching && !prevProps.tasks.isFetching)
        ) {
            let paramsStr = this.getParams();
            this.props.history.replace(`/tasks?${paramsStr}`);
            this.props.dispatch(getTask(filters));
        }

        if(
            ((prevProps.tasks.isFetching !== tasks.isFetching) && !tasks.isFetching) ||
            ((prevProps.tasks.isUpdating !== tasks.isUpdating) && !tasks.isUpdating) ||
            ((prevProps.tasks.isCreating !== tasks.isCreating) && !tasks.isCreating) ||
            ((prevProps.tasks.isTaking !== tasks.isTaking) && !tasks.isTaking)
        ) {
            
            let tasksList = this.sortObjectList(tasks.data);
            this.setState({tasksList, taskCount: tasks.count});
        }
    }

    sortObjectList(data) {
        if(!data || _.isEmpty(data)) return [];
        let value  =_.values(data);
        return _.orderBy(value, ['sort'], ['desc']);
    }

    parseUrl() {
        const params = new URLSearchParams(window.location.search);
        const {filters} = this.state;
        let newParams = {};

        for(var pair of params.entries()) {
            if(filters.hasOwnProperty(pair[0]) && pair[1]) {
                newParams[pair[0]] = pair[1];
            }
        }

        return newParams;
    }

    getParams() {
        const {filters} = this.state;
        let paramsArray = [];

        for(let key in filters) {
            if(filters[key] && filters[key] !== '') {
                paramsArray.push(`${key}=${filters[key]}`)
            }
        }
        return paramsArray.join("&");
    }

    fetchingMore = () => {
        const {tasks} = this.props;

        if(!tasks.next || tasks.isFetching) {
            return false;
        }
        
        this.props.dispatch(getTask({url: tasks.next}));
    }

    newTask = () => {
        this.props.history.push(`/tasks/new`);
    }

    handleActions = (action, task) => {
        const {dispatch, history} = this.props;

        /*
        Сделано так потому, что в FireFox-e выдавала ошибку,
        так-как есть лимит на отправленный объем данных,
        удаляем content, так как в content-e могли быть screenshot-ы [base64]
        */
        let taskWithOutContent = Object.assign({}, task);
        delete taskWithOutContent.content;

        if(action === 'edit') history.push(`/tasks/edit/${task.id}`, taskWithOutContent);
        else if(action === 'redirect') history.push(`/tasks/${task.id}`, taskWithOutContent);
        else if(action === 'show') {
            this.setState({
                table: {
                    ...this.state.table,
                    [task.id]: !(this.state.table[task.id] || false)
                }
            })
        }
        else if(action === 'start') dispatch(startDevelopTask(task));
        else if(action === 'stop') dispatch(stopDevelopTask(task));
        else if(action === 'close') dispatch(closeTask(task));
        else if(action === 'reopen') dispatch(reopenTask(task));
        else if(action === 'finish') dispatch(finishDevelopTask(task));
        else if(action === 'estimate_show') this.handleShow(task);
        else if(action === 'copy') this.handleDuplicate(task);
    }

    handleShow = (currentTask) => {
        this.setState({
            show: true,
            currentTask,
        });
    }

    handleDuplicate = (currentTask) => {
        console.log("Duplicate task", currentTask)
    }

    handleFilters = (field, value) => {
        this.setState({
            filters: {
                ...this.state.filters,
                [field]: value,
            }
        });
    }

    handleClear = () => {
        this.setState({
            filters: {
                q: '',
                project_id: null,
                contractor_id: null,
                state: null,
                owner_id: null,
            }
        })
    }

    onOpenSelect = (field) => {
        const {dispatch, projects, users} = this.props;

        if(field === 'project_id' && projects.next) {
            dispatch(getProject({url: projects.next}))
        }
        else if((field === 'owner_id' || field === 'contractor_id') && users.next) {
            dispatch(getUser({url: users.next}))
        }
    }

    estimateModalClose = () => {
        this.setState({
            show: false,
            currentTask: {},
        });
    }

    estimateModalSubmmit = (params) => {
        this.props.dispatch(estimateTask({
            ...this.state.currentTask,
            ...params
        }));
    }

    render() {
        const {projects, tasks:{isFetching, next, isTaking}, users, sessions, taskProcess} = this.props;
        const {filters, tasksList, taskCount, currentTask} = this.state;

        let projectOptions = [{value: null, label: 'Все компании'}];
        for(let key in projects.data) {
            projectOptions.push({
                value: projects.data[key].id,
                label: projects.data[key].title
            });
        }

        let userOptions = [];
        for(let key in users.data) {
            let item = users.data[key];
            if(item.first_name === '' && item.last_name === '') {
                continue
            }

            userOptions.push({
                value: item.id,
                label: `${item.first_name} ${item.last_name}`
            });
        }

        const currentProject = projectOptions.filter(item => item.value === filters.project_id)[0];
        const onlyMyTask = (parseInt(filters.contractor_id) === sessions.data.id);

        return (
            <div>
                <Header />

                <EstimateModal
                    show={this.state.show}
                    handleClose={this.estimateModalClose}
                    handleSubmit={this.estimateModalSubmmit}
                    taskProcess={taskProcess[currentTask.id]}
                />

                <Container className="general-container">
                    
                    <PageTitle title="Задачи" reRender={onlyMyTask}>
                        <Button variant="primary" size="md" onClick={this.newTask}>Добавить задачу</Button>

                        <div className="tasks__only_my">
                            <Form.Check 
                                custom
                                type="checkbox"
                                id="custom-checkbox"
                                label="Только мои задачи"
                                checked={onlyMyTask}
                                onChange={e => this.handleFilters("contractor_id", onlyMyTask ? null : sessions.data.id)}
                            />
                        </div>
                    </PageTitle>

                    <TaskFilter
                        count={taskCount || 0}
                        userOptions={userOptions}
                        filters={this.state.filters}
                        currentProject={currentProject}
                        projectOptions={projectOptions}
                        onOpenSelect={this.onOpenSelect}
                        handleFilters={this.handleFilters}
                        handleClear={this.handleClear}
                    />

                    <PeopleAreNotWorking users={users.data} />

                    <Row>
                        <Col>
                            <TaskTable
                                users={users}
                                projects={projects}
                                tasksList={tasksList}
                                isTaking={isTaking || false}
                                tableStates={this.state.table}
                                handleActions={this.handleActions}
                                />
                        </Col>
                    </Row>

                    <LoadMore
                        next={next}
                        isFetching={isFetching}
                        onPress={this.fetchingMore} />
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        tasks: state.tasks,
        projects: state.projects,
        sessions: state.sessions,
        taskProcess: state.taskProcess,
    }
}

export default connect(mapStateToProps)(TasksContainer)