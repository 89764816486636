import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import PropTypes from 'prop-types';

import { isSimilarObject } from '../../../../utils/helpers';

export default class EstimateModal extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }
  
    render() {
        const {show, handleClose} = this.props;

        return (
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Удалить чек-лист</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <p>Вы уверены, что хотите удалить чек-лист</p>
                    </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={this.props.handleClose}>
                    Отменить
                </Button>
                <Button variant="danger" onClick={this.props.handleSubmit}>
                    Удалить
                </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

EstimateModal.propTypes = {
    show: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    
}