import React, { Component } from 'react';
import { ButtonToolbar, Button, Badge, Dropdown, DropdownButton } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';

import { taskStates } from '../../../utils/Constants';
import EstimateBadge from '../../Commons/EstimateBadge';
import { dateTimeToDayName, myToString } from '../../../utils/helpers';
import checkBoxIcon from  '../../../assets/images/check-box.png';

export default class ListItem extends Component {

    handleOnPress(e, action) {
        e.preventDefault();
        e.stopPropagation();
        
        //Не открываем подр. инфо если выделяет текст
        if(!window.getSelection().isCollapsed) return false;
        this.props.onPress(action);
    }

    render() {
        const {item, created_at, owner, project, contractor} = this.props;
        if(!item) return false;

        let itemState = Object.assign({}, taskStates[item.state]);
        let infoArray = [];

        let ownerStr = owner ? `Постановщик ${owner.first_name} ${owner.last_name}` : 'Постановщик не указан';
        let dateTime = `${dateTimeToDayName(created_at)} в ${moment(created_at).format('HH:mm')}`
        infoArray.push(ownerStr)
        infoArray.push(dateTime);

        if(item.reopen_count) {
            infoArray.push(`Количество переоткрытий: ${item.reopen_count}`)
        }


        let hasDescription = true;
        if(myToString(item.content) === '' || myToString(item.content) === '<p></p>' || myToString(item.content) === '<p></p>\n') {
            hasDescription = false;
        }

        let checkListString;
        if(item.checklist_task_count > 0) {
            checkListString = `${item.checklist_task_finish_count}/${item.checklist_task_count}`
        }

        return (
            [<tr key="1" onClick={(e) => this.handleOnPress(e, 'show')}>
                <td>{item.id}</td>
                <td className="td-status">
                    <Badge variant={itemState.color} title={itemState.title}>
                        {itemState.shortTitle || itemState.title}
                    </Badge>

                    {project && <div className="td__project">{project.title}</div>}
                </td>
                <td className="tasks-table__buttons">
                    <ButtonToolbar>
                        {
                            item.can_stop_develop ?
                            <Button variant="danger" size="sm" onClick={(e) => this.handleOnPress(e, 'stop')}>Отложить</Button>
                            : null
                        }

                        {
                            item.can_finish ?
                            <Button variant="success" size="sm" onClick={(e) => this.handleOnPress(e, 'finish')}>Выполнено</Button>
                            : null
                        }

                        {
                            item.can_reopen ?
                            <Button variant="danger" size="sm" onClick={(e) => this.handleOnPress(e, 'reopen')}>Переоткрыть</Button>
                            : null
                        }

                        {
                            item.can_start_develop ?
                            <Button variant="primary" size="sm" onClick={(e) => this.handleOnPress(e, 'start')}>Взять задачу</Button>
                            : null
                        }

                        {
                            item.can_close ?
                            <Button variant="success" size="sm" onClick={(e) => this.handleOnPress(e, 'close')}>Проверено</Button>
                            : null
                        }

                        {
                            //!showDropDown ? null :
                            <DropdownButton id="more-actions" size="sm" title="" onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}>
                                {
                                    !item.can_edit ? null :
                                    <Dropdown.Item href="#" onClick={(e) => this.handleOnPress(e, 'edit')}>
                                        Редактировать
                                    </Dropdown.Item>
                                }
                                {
                                    !item.can_set_estimate ? null :
                                    <Dropdown.Item href="#" onClick={(e) => this.handleOnPress(e, 'estimate_show')}>
                                        {item.estimate_hours ? 'Переоценить задачу' : 'Оценить задачу'}
                                    </Dropdown.Item>
                                }
                                <Dropdown.Item href="#" onClick={(e) => this.handleOnPress(e, 'copy')}>
                                    Копировать
                                </Dropdown.Item>
                            </DropdownButton>
                        }

                        <Button variant="link" size="sm" onClick={(e) => this.handleOnPress(e, 'redirect')}>Перейти</Button>
                    </ButtonToolbar>
                </td>
                <td title={hasDescription ? '' : 'Без описания'}>
                    <EstimateBadge data={{
                        spent_hours: item.spent_hours,
                        estimate_hours: item.estimate_hours,
                    }}/>
                    <span>
                        <strong>{` ${item.title}`}</strong>
                        {
                            <p className="task-table__contractor">
                                {infoArray.join(", ")}
                            </p>
                        }
                        {
                            !contractor ? null :
                            <p className="task-table__contractor">
                                {`Исполнитель: ${contractor.first_name} ${contractor.last_name}`}
                            </p>
                        }
                        {
                            !checkListString ? null : //check-box
                            <p className="task-table__contractor" style={{color: '#777'}}>
                                <img src={checkBoxIcon} style={{opacity: '.5',marginRight: '3px'}}/> {` ${checkListString}`}
                            </p>
                        }
                    </span>
                </td>
            </tr>,
            <tr key="2" className={`${this.props.itemActive ? 'td-hidden-show' : 'hidden'}`}>
                <td colSpan="4">
                    <div>
                        <h6>Подробное описание</h6>
                        {
                            hasDescription
                            ? <div dangerouslySetInnerHTML={{__html: item.content}} />
                            : 'Без описания'
                        }
                    </div>
                </td>
            </tr>]
        )
    }
}

ListItem.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    contractor: PropTypes.object,
    // item: PropTypes.object.isRequired,
    onPress: PropTypes.func.isRequired,
};