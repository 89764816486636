import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

import { SignInForm } from '../../components/Auth/SignInForm';
import { login, authenticate } from '../../actions/sessionActions';
import { myToString, isSimilarObject } from '../../utils/helpers';

class SignInContainer extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            buttonDisable: false,
            authorize: false,
            params: {
                username: '', //garpix
                password: '' //jeka2016
            }
        }
    }

    componentDidMount() {
        const {sessions} = this.props;

        if(sessions.loggedIn) {
            this.props.history.replace("/")
        }

        this.setButtonState();
    }

    componentDidUpdate(prevProps, prevState) {
        const {params} = this.state;
        const {sessions} = this.props;
        const fetchingFinished = prevProps.sessions.isFetching !== sessions.isFetching && !sessions.isFetching;

        if(
            !isSimilarObject(prevState.params, params)
        ) {
            this.setButtonState();
        }

        if(
            fetchingFinished && prevProps.sessions.authorize !== sessions.authorize &&
            sessions.authorize
        ) {
            this.setState({
                authorize: true,
                buttonDisable: true,
            });
            this.props.dispatch(authenticate());
        }

        if(
            fetchingFinished && prevProps.sessions.loggedIn !== sessions.loggedIn &&
            sessions.loggedIn
        ) {
            setTimeout(() => {
                this.props.history.replace('/');
            }, 1500);
        }
    }

    handleOnChange(field, value) {
        this.setState({
            params: {
                ...this.state.params,
                [field]: value,
            }
        });
    }

    setButtonState() {
        let state = this.checkForSomeEmptyInput();
        this.setState({buttonDisable: state});
    }

    checkForSomeEmptyInput() {
        let state = false;
        if(
            myToString(this.state.params.username) === '' ||
            myToString(this.state.params.password) === ''
        ) {
            state = true;
        }

        return state;
    }

    handleSubmit(e) {
        e.preventDefault();
        
        this.props.dispatch(login(this.state.params));
    }

    render() {
        const {sessions: {isFetching, errors}} = this.props;
        
        return (
            <Container className="auth-container">
                <Row className="justify-content-sm-center">
                    <Col md="auto">
                        <h1>Вход</h1>
                    </Col>
                </Row>
                <Row className="justify-content-sm-center auth-form">
                    <Col xs={4}>
                        <SignInForm 
                            errors={errors}
                            data={this.state}
                            isFetching={isFetching}
                            handleOnChange={this.handleOnChange.bind(this)}
                            handleSubmit={this.handleSubmit.bind(this)}
                        />
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        sessions: state.sessions,
    }
}

export default connect(mapStateToProps)(SignInContainer)