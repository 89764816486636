import axios from 'axios';
import * as types from './actionTypes';
import { URL, API } from '../utils/Constants';
import { setToken, getHeader } from '../utils/helpers';

function loginSuccess(payload) {
    return {
        type: types.LOGIN_SUCCESS,
        payload,
    }
}

function loginFailure(errors) {
    return {
        type: types.LOGIN_FAILURE,
        errors
    }
}

export function login(params) {
    return function(dispatch) {
        
        dispatch({type: types.LOGIN_REQUEST});

        return axios.post(
            `${URL}api/v1/token-auth/`,    //garpix / jeka2016
            params,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        .then(response => {
            const {data:{token}} = response;
            console.log("login success", response.data);

            setToken(token);
            dispatch(loginSuccess(token));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 

            console.warn('login error', errors.data)
            dispatch(loginFailure(errors.data || {}));
        });
    }
}



function authenticateSuccess(payload) {
    return {
        type: types.AUTH_SUCCESS,
        payload,
    }
}

function authenticateFailure(errors) {
    return {
        type: types.AUTH_FAILURE,
        errors
    }
}

export function authenticate(token) {
    return function(dispatch) {
        
        dispatch({type: types.AUTH_REQUEST});

        return axios.get(
            `${API}user/current/`,    //garpix / jeka2016
            {
                headers: getHeader(token)
            }
        )
        .then(response => {
            // console.log("authenticate success", response.data);
            dispatch(authenticateSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('authenticate error', errors.data)
            dispatch(authenticateFailure(errors.data || {}));
        });
    }
}