import React, { PureComponent } from 'react';
import PropsTypes from 'prop-types';

export default class EmptyTextArea extends PureComponent {

    render() {
        const {
            onClick,
        } = this.props;

        return (
            <div 
                className="checklist__empty-textarea"
                onClick={onClick}>
                Введите текст
            </div>
        )
    }
}

EmptyTextArea.propTypes = {
    onClick: PropsTypes.func.isRequired,
}