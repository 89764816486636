import React from 'react';
import { Route, Redirect } from "react-router-dom";

export default ({ sessions, component: Component, ...rest }) => {
  
    if(sessions.isFetching) return null;

    return (
        sessions.loggedIn
        ? <Route {...rest} render={props => (<Component {...props}/>)}/>
        : <Redirect to="/login" />
    )
}