import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Container, Row, Col, Form, Button, ListGroup, Card } from 'react-bootstrap';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from "html-to-draftjs";
// import DateTimePicker from 'react-datetime-picker';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Header from '../../components/Commons/Header';
import PageTitle from '../../components/Commons/PageTitle';
import CheckList from '../../components/Task/TaskEdit/CheckList';
import SelectField from '../../components/Task/TaskSingle/Form/SelectField';
import DoesNotExists from '../../components/Task/DoesNotExists';
import ErrorStroke from '../../components/Commons/ErrorStroke';
import ProjectSelect from '../../components/Projects/ProjectSelect'
import { getTaskById, createTask, updateTask } from '../../actions/taskActions';
import { createCheckList, getCheckList, updateCheckList, deleteCheckList, setStateCheckList } from '../../actions/checkListActions';
import { isSimilarObject, myToString } from '../../utils/helpers';
import { getProject } from '../../actions/projectActions';
import { getUser } from '../../actions/userActions';

class TaskSingleEdit extends Component {

    constructor(props) {
        super(props);

        let param_id = props.match.params.id;
        let initTask = this.initCurrentTask(props.location.state);
        let isNew = (!param_id);
        let doesNotExits = false;
        let someError = '';
                    
        if(isNew) props.history.replace("/tasks/new")

        if(!isNew && param_id) {
            props.dispatch(getTaskById({id: param_id}));
        }

        if(!isNew && param_id && initTask.can_edit === false) {
            doesNotExits = true;
            someError = 'Вы не можете редактировать данную задачу'
        }

        this.state = {
            focused: '',
            show: false,
            checkListShow: false,
            checkList: [],
            submitState: false,
            currentTask: initTask,
            currentTaskOriginal: initTask,
            someError,
            doesNotExits,
            param_id,
            isNew,
        }
    }

    componentDidMount() {
        const {currentTask} = this.state;
        if(!currentTask) return false;

        this.props.dispatch(getCheckList({
            task_id: currentTask.id
        }));

        // document.addEventListener('copy', this.handleCopy);
        document.addEventListener('paste', this.handlePaste);
    }

    componentDidUpdate(prevProps, prevState) {
        const {tasks, match:{params}, checkList} = this.props;
        const {isNew, param_id, currentTask, submitState} = this.state;

        let isCreating = prevProps.tasks.isCreating !== tasks.isCreating && !tasks.isCreating;
        let isUpdating = prevProps.tasks.isUpdating !== tasks.isUpdating && !tasks.isUpdating;
        let isFetching = prevProps.tasks.isFetching !== tasks.isFetching && !tasks.isFetching;

        if(isFetching && !isNew) {
            if(tasks.errors) {
                this.setState({doesNotExits: true});
            }
            else {
                let currentTask = tasks.data[param_id];
                if(currentTask.can_edit === false) {
                    this.setState({
                        doesNotExits: true,
                        someError: 'Вы не можете редактировать данную задачу',
                    });
                    return false;
                }

                this.setState({
                    currentTask: this.initCurrentTask(currentTask),
                    currentTaskOriginal: this.initCurrentTask(currentTask),
                });
            }
        }

        if(isCreating && submitState) {
            if(!tasks.errors) {
                setTimeout(() => {
                    this.props.history.push(`/tasks?project_id=${currentTask.project.value}`)
                }, 100);
            }
            this.setState({submitState: false});
        }

        if(isUpdating && submitState) {
            if(!tasks.errors) {
                setTimeout(() => {
                    this.props.history.push(`/tasks/${currentTask.id}`)
                }, 100);   
            }
            this.setState({submitState: false});
        }

        if(!isSimilarObject(prevProps.match.params, params)) {
            this.setState({
                isNew: (params.id === 'new'),
                doesNotExits: false,
            })
        }

        if(
            (prevProps.checkList.isFetching !== checkList.isFetching && !checkList.isFetching) ||
            (prevProps.checkList.isCreating !== checkList.isCreating && !checkList.isCreating)
        ) {
            let array = _.values(checkList.data);
            let newArray = array.filter(item => item.task === currentTask.id);

            this.setState({
                checkList: newArray,
                checkListShow: newArray.length ? true : this.state.checkListShow,
            });
        }
    }

    getDefaultContent(content) {
        if(myToString(content) === '' || content === '<p></p>' || content === '<p></p>\n') {
            return EditorState.createEmpty();
        }

        const contentBlock = htmlToDraft(content);

        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            return editorState;
        }
       
        // return EditorState.createWithContent(
        //     ContentState.createFromBlockArray(
        //         convertFromHTML(content)
        //     )
        // );
    }

    initCurrentTask(state) {
        let newState = Object.assign({}, state);
        newState.content = this.getDefaultContent(newState.content);

        let plan_start_at = new Date();
        let plan_end_at = null;
        if(newState.plan_start_at) {
            plan_start_at = new Date(newState.plan_start_at)
        }
        if(newState.plan_end_at) {
            plan_end_at = new Date(newState.plan_end_at)
        }

        return {
            title: '',
            // content: '',
            estimate_hours: 0,
            milestone: null,
            milestone_object: null,
            
            project: null,
            contractor: null,
            reopen_count: 0,
            spent_hours: 0,
            state: "OPEN",

            ...newState,

            plan_start_at,
            plan_end_at,
        };
    }

    handleOnChange(field, value) {
        this.setState({
            currentTask: {
                ...this.state.currentTask,
                [field]: value,
            }
        })
    }

    addCheckList = () => {
        this.setState({
            checkListShow: true,
        });
    }

    handleSubmit = () => {
        const {currentTask:{
            id,
            title,
            content,
            project,
            contractor,
            // plan_start_at,
            // plan_end_at,
        }} = this.state;

        const rawContentState = convertToRaw(content.getCurrentContent());
        const markup = draftToHtml(rawContentState);
        let params = {
            title,
            content: markup,//.replace(/rn/g,"n"),
            project: project.value,
            contractor,
            // plan_start_at: localDateTimeToUTC(plan_start_at) || null,
            // plan_end_at: localDateTimeToUTC(plan_end_at) || null,
        }

        this.setState({submitState: true});

        if(this.state.isNew) {
            this.props.dispatch(createTask(
                params,
                newTask => this.afterCreateNewTask(newTask)
            ));
        }
        else {
            this.props.dispatch(updateTask(params, id));
        }
    }

    afterCreateNewTask(task) {
        for(let key in this.state.checkList) {
            this.props.dispatch(createCheckList({
                task: task.id,
                ...this.state.checkList[key],
            }));
        }
    }

    getFileBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        // Since FileReader is asynchronous,
        // we need to pass data back.
        reader.onload = () => callback(reader.result);
        // TODO: catch an error
        reader.onerror = error => {};
    };
      
    imageUploadCallback = file => new Promise(
        (resolve, reject) => this.getFileBase64(
            file,
            data => {
                resolve({ data: { link: data } })
            }
        )
    );

    handleAddItem = (item) => {
        if(!this.state.currentTask) return false;

        if(this.state.isNew) {
            this.setState({
                checkList: [
                    ...this.state.checkList,
                    item,
                ]
            });
            return;
        }

        this.props.dispatch(createCheckList({
            task: this.state.currentTask.id,
            ...item,
        }));
    }

    handleRemoveItem = (item, index) => {
        if(!this.state.currentTask) return false;

        if(this.state.isNew) {
            let newState = Object.assign([], this.state.checkList);
            newState.splice(index, 1)
            this.setState({
                checkList: newState
            })
            return;
        }

        this.props.dispatch(deleteCheckList(item));
    }

    handleUpdateItem = (item, index) => {
        if(!this.state.currentTask) return false;

        let params = {
            task: this.state.currentTask.id,
            content: item.content,
        }

        if(this.state.isNew) {
            let newState = Object.assign([], this.state.checkList);
            newState.splice(index, 1, item)
            this.setState({
                checkList: newState
            })
            return;
        }

        this.props.dispatch(updateCheckList(params, item.id));
    }

    setStateCheckList = (item, type) => {
        if(!this.state.currentTask || !item.state || !type) return false;

        let params = {
            task: this.state.currentTask.id,
            state: item.state,
        }

        this.props.dispatch(setStateCheckList(params, item.id, type));
    }

    onOpenSelect = (field) => {
        const {dispatch, projects, users} = this.props;

        if(field === 'project' && projects.next) {
            dispatch(getProject({url: projects.next}))
        }
        else if((field === 'contractor') && users.next) {
            dispatch(getUser({url: users.next}))
        }
    }

    handleCopy = (e) => {
        console.log("copy", e.clipboardData.getData("image"))
        e.preventDefault();
    };



    /** 
     * Следует переписать этот код
     * В данный момент добавляет изображения только в описание задачи, (в чеклисты не добавляет)
     * Потому, что жестко прописано self.state.currentTask.content
     * Чтобы решить эту проблему начал писать компонент обертки. (Components/Commons/TextEditorWrap)
     * 
    */

    handlePaste = (event) => {
        const {content} = this.state.currentTask;

        if(!event.target) return

        let self = this;
        var items = (event.clipboardData || event.originalEvent.clipboardData).items;
        
        for (let index in items) {
            var item = items[index];
            if (item.kind === 'file') {
                var blob = item.getAsFile();
                var reader = new FileReader();

                const rawContentState = convertToRaw(content.getCurrentContent());
                const markup = draftToHtml(rawContentState);

                reader.onload = function(event){
                    const html = `${markup}<img src="${event.target.result}"/><p></p>`;
                    const contentBlock = htmlToDraft(html);

                    if (contentBlock) {
                        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                        const editorState = EditorState.createWithContent(contentState);

                        self.setState({currentTask: {
                            ...self.state.currentTask,
                            content: editorState
                        }})
                    }
                };

                reader.readAsDataURL(blob);
            }
        }
    };

    render() {
        const {tasks, projects, users} = this.props;
        const { submitState, currentTask, checkList } = this.state;
        let taskTitle = `Задача № ${currentTask.id || ''}`;
        let errors = Object.assign({}, tasks.errors);

        if(this.state.isNew) {
            taskTitle = 'Новая задача';
        }

        if (this.state.doesNotExits) {
            return  <DoesNotExists 
                        errorMessage={errors.detail || this.state.someError}
                        onPress={() => this.props.history.replace("/tasks/new")}
                    />
        }

        const projectOptions = Object.keys(projects.data).map(key => {
            let item = projects.data[key];
            return {value: item.id, label: item.title}
        });

        const userOptions = Object.keys(users.data).map(key => {
            let item = users.data[key];
            return {value: item.id, label: `${item.first_name} ${item.last_name}`}
        });
        
        const project = projectOptions.filter(item => item.value === currentTask.project)[0];
        const contractor = userOptions.filter(item => item.value === currentTask.contractor)[0];
        const owner = users.data[currentTask.owner];

        return (
            <div>
                <Header />

                <Container className="general-container">
                    <PageTitle title={taskTitle} reRender={submitState}>
                        <Button
                            variant="primary"
                            size="md"
                            disabled={submitState}
                            onClick={this.handleSubmit}>
                            {submitState ? 'Сохраняется...' : 'Сохранить'}
                        </Button>
                    </PageTitle>
                    <Row>
                        <Col size="md" xs={8}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Заголовок</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={currentTask.title}
                                            onChange={(e) => this.handleOnChange('title', e.target.value)}
                                            placeholder="Название задачи"
                                        />
                                        <Form.Text className="text-muted">
                                            Объязательно для заполнения
                                        </Form.Text>
                                    </Form.Group>
                                    <ErrorStroke error={errors.title}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Подробное описание</Form.Label>
                                        <Editor
                                            ref= { (editor) => { this.__editor=editor } }
                                            editorState={currentTask.content}
                                            wrapperClassName="text-editor__wrapper"
                                            editorClassName="text-editor__body"
                                            placeholder="Введите описание"
                                            onEditorStateChange={(value) => this.handleOnChange('content', value)}
                                            onFocus={() => console.log("on focus")}
                                            toolbar={{
                                                image: {
                                                    urlEnabled: false,
                                                    uploadEnabled: true,
                                                    previewImage: true,
                                                    uploadCallback: this.imageUploadCallback
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <CheckList 
                                list={checkList}
                                show={this.state.checkListShow}
                                handleAddItem={this.handleAddItem}
                                handleRemoveItem={this.handleRemoveItem}
                                handleUpdateItem={this.handleUpdateItem}
                                setStateCheckList={this.setStateCheckList}
                                cancelCheckList={() => {
                                    this.setState({checkListShow: false})
                                }}
                            />

                            <Row>
                                <Col xs={6}>
                                    <ProjectSelect
                                        label="Проект"
                                        value={project}
                                        onOpenSelect={() => this.onOpenSelect('project')}
                                        onChange={(value) => this.handleOnChange('project', value)}
                                    />
                                    {/* <SelectField
                                        label="Проект"
                                        options={projectOptions}
                                        defaultValue={project}
                                        onChange={(value) => this.handleOnChange('project', value)}
                                        onOpenSelect={() => this.onOpenSelect('project')}
                                    /> */}
                                    <ErrorStroke error={errors.project}/>
                                </Col>
                                <Col xs={6}>
                                    <SelectField
                                        label="Исполнитель"
                                        options={userOptions}
                                        defaultValue={contractor}
                                        onChange={(value) => this.handleOnChange('contractor', value)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={3}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Добавить на задачу</Card.Title>
                                    <ListGroup>
                                        <ListGroup.Item action disabled={this.state.checkListShow} onClick={this.addCheckList}>
                                            Чек-лист
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>

                            {
                                this.state.isNew ? null :
                                <Card className="text-center" style={{marginTop:'10px'}}>
                                    <Card.Body>
                                        <Card.Title>Постановщик задачи</Card.Title>
                                        <Card.Text>
                                        {
                                            owner
                                            ? `${owner.first_name || ''} ${owner.last_name || ''}`
                                            : 'Не указан'
                                        }
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        tasks: state.tasks,
        projects: state.projects,
        checkList: state.checkList,
    }
}

export default connect(mapStateToProps)(TaskSingleEdit)