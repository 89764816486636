import axios from 'axios';
import * as types from './actionTypes';
import { API } from '../utils/Constants';
import { getHeader } from '../utils/helpers';

function getProjectSuccess(payload) {
    return {
        type: types.LOAD_PROJECT_SUCCESS,
        payload,
    }
}

function getProjectFailure(errors) {
    return {
        type: types.LOAD_PROJECT_FAILURE,
        errors
    }
}

export function getProject(args = {}) {
    return function(dispatch) {
        let url = `${API}project/`;
        let params = Object.assign({}, {
            page_size: 9999
        }, args);

        if(args.url) {
            url = args.url;
            delete args.url;
        }
        
        dispatch({type: types.LOAD_PROJECT_REQUEST});

        return axios.get(
            url,
            {
                params,
                headers: getHeader()
            }
        )
        .then(response => {
            dispatch(getProjectSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getProject error', errors.data)
            dispatch(getProjectFailure(errors.data || {}));
        });
    }
}



function getProjectByIdSuccess(payload) {
    return {
        type: types.LOAD_PROJECT_BY_ID_SUCCESS,
        payload,
    }
}

function getProjectByIdFailure(errors) {
    return {
        type: types.LOAD_PROJECT_BY_ID_FAILURE,
        errors
    }
}

export function getProjectById(project) {
    return function(dispatch) {
        
        dispatch(setProcessValue(project.id, 'get', true));
        dispatch({type: types.LOAD_PROJECT_BY_ID_REQUEST});

        return axios.get(
            `${API}project/${project.id}/`,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("getProjectByIdSuccess success", response.data);
            dispatch(getProjectByIdSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getProjectByIdFailure error', errors.data)
            dispatch(getProjectByIdFailure(errors.data || {}));
        })
        .then(function () {
            // always executed
            dispatch(setProcessValue(project.id, 'get', false));
        });
    }
}



function getTimeTrackSuccess(payload) {
    return {
        type: types.LOAD_TIME_TRACK_SUCCESS,
        payload,
    }
}

function getTimeTrackFailure(errors) {
    return {
        type: types.LOAD_TIME_TRACK_FAILURE,
        errors
    }
}

export function getTimeTrack(project) {
    return function(dispatch) {
        
        dispatch(setProcessValue(project.id, 'get_track', true));
        dispatch({type: types.LOAD_TIME_TRACK_REQUEST});

        return axios.get( //GET /garpix_workflow/api/v1/project/<project_id>/time_track/?limit=100
            `${API}project/${project.id}/time_track/?limit=100`,
            {
                headers: getHeader()
            }
        )
        .then(response => {
            console.log("getTimeTrackSuccess success", response.data);
            dispatch(getTimeTrackSuccess(response.data));
        })
        .catch(error => {
            const errors = Object.assign({}, error.response); 
            console.error('getTimeTrackFailure error', errors.data)
            dispatch(getTimeTrackFailure(errors.data || {}));
        })
        .then(function () {
            dispatch(setProcessValue(project.id, 'get_track', false));
        });
    }
}



function setProcessValue(project_id, action_name, action_state) {
    return {
        type: types.SET_PROJECT_PROCESS_VALUE,
        payload: {
            project_id,
            action_name,
            action_state,
        },
    }
}
