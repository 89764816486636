import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Container, Row, Col, Button } from 'react-bootstrap';

import Header from '../../components/Commons/Header';
import PageTitle from '../../components/Commons/PageTitle';
import ProjectHeaderInfo from '../../components/Projects/ProjectView/ProjectHeaderInfo';
import { getProjectById, getTimeTrack } from '../../actions/projectActions';
import { getMilestone } from '../../actions/milestoneActions';
import { isSimilarObject } from '../../utils/helpers';
import DoesNotExists from '../../components/Task/DoesNotExists';
import MilestoneTable from '../../components/Projects/ProjectView/Milestone/DataTable';
import DataTable from '../../components/Projects/ProjectView/TimeTrack/DataTable';
import { projectStates } from '../../utils/Constants'

import api from '../../api'

// let projectStatesOptions = Object.keys(projectStates).map(key => {
//     return {value: key, label: projectStates[key].title}
// })

// projectStatesOptions = [{value: null, label: 'Все статусы'}, ...projectStatesOptions]

class ProjectView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            currentProject: {},
            milestoneList: []
        }
    }

    componentDidMount() {
        const id = this.props.match.params.id;
        // currentProject
        api.getProject({ id })
        .then(response => {
            const currentProject = response.data
            this.setState({
                currentProject,
                milestoneList: currentProject.milestones 
            })
        })
    }

    addMilestone = () => {
        this.props.history.replace("/projects/milestone/new");
    }

    editMilestone = (item) => {
        if(!item) return false;
        this.props.history.replace(`/projects/milestone/edit/${item.id}`, item);
    }

    deleteMilestone = (id) => {
        const isDelete = window.confirm("Удалить?");
        if(isDelete) {
            const { milestoneList } = this.state;
            this.setState({
                milestoneList: milestoneList.filter(item => item.id !== id)
            })
            api.deleteMilestone({ id })
        }
    }

    // updateProject = (field, value) => {
    //     let currentProject = {...this.state.currentProject}
    //     currentProject[field] = value
    //     this.setState({
    //         currentProject
    //     })
    //     let params = {
    //         id: currentProject.id
    //     }
    //     params[field] = value
    //     api.updateProject(params)
    // }

    toArchiveProject = () => {
        const { currentProject } = this.state
        const isDelete = window.confirm("Архивировать?");
        if(isDelete) {
            api.archiveProject({ id: currentProject.id })
            .then(({ data }) => {
                this.setState({
                    currentProject: data
                })
            })
        }
    }

    render() {
        const {currentProject, milestoneList} = this.state;
        // const {milestones, projects} = this.props;
        // const milestoneLength = Object.keys(milestones.data).length;

        // if (this.state.doesNotExits) {
        //     return  <DoesNotExists
        //                 errorMessage={(projects.errors && projects.errors.detail) || 'Проект не найден'}
        //             />
        // }

        if(!currentProject) return false;
        
        let contractor = this.props.users.data[currentProject.contractor];

        return (
            <div>
                <Header />

                <Container className="general-container">

                    <PageTitle title={currentProject.title}>
                        <Button
                            variant="primary"
                            size="md"
                            onClick={this.addMilestone}>
                            Добавить этап
                        </Button>
                    </PageTitle>

                    <div className="line"/>

                    <Row className="task-state__container">
                        {currentProject.can_archive ? (
                            <Col xs={12}>
                                <Button
                                    variant="primary"
                                    size="md"
                                    onClick={this.toArchiveProject}
                                >
                                    В архив
                                </Button>
                            </Col>
                        ) : null}
                    </Row>

                    {/* <ProjectHeaderInfo 
                        currentProject={currentProject}
                        contractor={contractor}
                        milestoneLength={milestoneList.length}
                    /> */}
                    
                    <Row style={{marginTop:'20px'}}>
                        <Col>
                            <h5 style={{marginBottom:'0'}}>Список этапов</h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MilestoneTable 
                                data={milestoneList} 
                                onPress={this.editMilestone}
                                deleteMilestone={this.deleteMilestone}
                                />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <DataTable data={this.props.timeTrack} />
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        users: state.users,
        projects: state.projects,
        timeTrack: state.timeTrack,
        milestones: state.milestones,
        projectProcess: state.projectProcess,
    }
}

export default connect(mapStateToProps)(ProjectView)