import * as types from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function reducer(state = initialState.task, action) {
    switch (action.type) {
        case types.LOAD_TASK_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.LOAD_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...(action.moreData ? state.data : {}),
                    ..._.mapKeys(action.payload.results, 'id')
                },
                ids: [
                    ..._.union(
                        (action.moreData ? state.ids : []),
                        [..._.map(action.payload.results, 'id')]
                        )
                    ],
                count: action.payload.count,
                next: action.payload.next,
                timestamp: new Date(),
            });
        case types.LOAD_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.LOAD_TASK_BY_ID_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.LOAD_TASK_BY_ID_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: ((state.count || 0) + 1),
                next: state.next,
            });
        case types.LOAD_TASK_BY_ID_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.UPDATE_TASK_REQUEST:
            return Object.assign({}, state, {
                isUpdating: true,
            });
        case types.UPDATE_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isUpdating: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: (state.count || 0),
                next: state.next,
            });
        case types.UPDATE_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isUpdating: false,
            });
        
        
        case types.CREATE_TASK_REQUEST:
            return Object.assign({}, state, {
                isCreating: true,
            });
        case types.CREATE_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isCreating: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: ((state.count || 0) + 1),
                next: state.next,
            });
        case types.CREATE_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isCreating: false,
            });
        
        
        case types.START_DEV_TASK_REQUEST:
            return Object.assign({}, state, {
                isTaking: true,
            });
        case types.START_DEV_TASK_SUCCESS:
            for(let key in state.data) {
                if(
                    state.data[key].state === "DEVELOP" &&
                    state.data[key].contractor === action.contractor
                ) {
                    state.data[key].state = "OPEN"
                }
            }

            return Object.assign({}, state, {
                errors: null,
                isTaking: false,
                data: {
                    ...state.data,
                    ..._.mapKeys(action.payload, 'id')
                },
                count: state.count,
                next: state.next,
            });
        case types.START_DEV_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isTaking: false,
            });
        
        
        case types.STOP_DEV_TASK_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.STOP_DEV_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload,
                },
                count: state.count,
                next: state.next,
            });
        case types.STOP_DEV_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.FINISH_DEV_TASK_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.FINISH_DEV_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload,
                },
                count: state.count,
                next: state.next,
            });
        case types.FINISH_DEV_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.REOPEN_TASK_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.REOPEN_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload,
                },
                count: state.count,
                next: state.next,
            });
        case types.REOPEN_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.ESTIMATE_TASK_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.ESTIMATE_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload,
                },
                count: state.count,
                next: state.next,
            });
        case types.ESTIMATE_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.CLOSE_TASK_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.CLOSE_TASK_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload,
                },
                count: state.count,
                next: state.next,
            });
        case types.CLOSE_TASK_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });

        
        default:
            return state;
    }
}



export function taskProcess(state = {}, action) {
    switch (action.type) {
        case types.SET_TASK_PROCESS_VALUE:
            return Object.assign({}, state, {
                [action.payload.task_id]: {
                    ...(state[action.payload.task_id] || {}),
                    [action.payload.action_name]: action.payload.action_state
                }
            });
            
        
        default:
            return state;
    }
}