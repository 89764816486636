import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import DateTimePicker from 'react-datetime-picker';

import Header from '../../components/Commons/Header';
import PageTitle from '../../components/Commons/PageTitle';
import SelectField from '../../components/Task/TaskSingle/Form/SelectField';
import { getMilestoneById, createMilestone, updateMilestone } from '../../actions/milestoneActions';
import ErrorStroke from '../../components/Commons/ErrorStroke';
import { isSimilarObject, myToString } from '../../utils/helpers';
import moment from 'moment';
import { milestoneStates } from '../../utils/Constants'

const milestoneStatesOptions = Object.keys(milestoneStates).map(key => {
    return {value: key, label: milestoneStates[key].title}
})

class MilestoneEdit extends Component {

    constructor(props) {
        super(props);
        
        let param_id = props.match.params.id;
        let currentMilestone = this.initData(props.location.state);
        let isNew = (!param_id);

        if(isNew) props.history.replace("/projects/milestone/new")

        if(param_id) {
            props.dispatch(getMilestoneById({id: param_id}));
        }

        this.state = {
            buttonDisable: false,
            isNew,
            param_id,
            currentMilestone,
            currentMilestoneOriginal: currentMilestone,
        }
    }

    componentDidMount() {
        this.setButtonState();
    }

    componentDidUpdate(prevProps, prevState) {
        const {milestones, milestoneProcess} = this.props;
        const {currentMilestone, param_id} = this.state;

        let process1 = milestoneProcess[param_id] || {};
        let process2 = prevProps.milestoneProcess[param_id] || {};

        if(process1.get !== process2.get) {
            if(!process1.get) {
                if(!milestones.errors) {
                    let currentMilestone = this.initData(milestones.data[param_id]);
                    this.setState({
                        currentMilestone,
                        currentMilestoneOriginal: currentMilestone,
                    });
                    this.setButtonState();
                }
            }
        }

        if(process1.update !== process2.update && !process1.update) {
            if(!process1.update) {
                this.setButtonState();

                if(!milestones.errors) {
                    let currentMilestone = this.initData(milestones.data[param_id]);

                    this.setState({
                        currentMilestone,
                        currentMilestoneOriginal: currentMilestone,
                        successMessage: 'Изменения сохранены!'
                    });

                    setTimeout(() => {
                        this.setState({ successMessage: null})
                    }, 1500);
                }
            }
        }

        if(prevProps.milestones.isCreating !== milestones.isCreating && !milestones.isCreating) {
            if(!milestones.errors) {
                setTimeout(() => {
                    this.props.history.push(`/projects/${currentMilestone.project}`)
                }, 100);
            }
        }

        if(!isSimilarObject(prevState.currentMilestone, currentMilestone)) {
            this.setButtonState();
        }
    }

    initData(state) {
        let newState = Object.assign({}, state);
        let plan_start_at = new Date();
        let plan_end_at = null;

        if(newState.plan_start_at) {
            plan_start_at = new Date(newState.plan_start_at)
        }
        if(newState.plan_end_at) {
            plan_end_at = new Date(newState.plan_end_at)
        }

        return {
            title: '',
            parent: 0,
            plan_estimate: 0,
            project: null,
            state: "OPEN",

            ...newState,

            plan_start_at,
            plan_end_at,
        };
    }

    setButtonState() {
        let state = this.checkForSomeEmptyInput();
        this.setState({buttonDisable: state});
    }

    checkForSomeEmptyInput() {
        const {
            currentMilestone,
            currentMilestoneOriginal,
        } = this.state;
        let check = true;
        
        if(
            myToString(currentMilestone.title) !== '' &&
            currentMilestone.project &&
            !isSimilarObject(currentMilestone, currentMilestoneOriginal)
        ) {
            check = false;
        }

        return check;
    }

    handleOnChange(field, value) {
        this.setState({
            currentMilestone: {
                ...this.state.currentMilestone,
                [field]: value,
            }
        })
    }

    handleSubmit = () => {
        const {
            id,
            price,
            title,
            parent,
            project,
            price_paid,
            plan_end_at,
            plan_start_at,
            plan_estimate,
            state,
        } = this.state.currentMilestone;

        let params = {
            id,
            price,
            title,
            parent,
            project,
            price_paid,
            plan_estimate,
            state
        }

        if(plan_start_at) {
            params.plan_start_at = moment(plan_start_at).format("YYYY-MM-DD HH:mm")
        }
        if(plan_end_at) {
            params.plan_end_at = moment(plan_end_at).format("YYYY-MM-DD HH:mm")
        }

        if(this.state.isNew) {
            this.props.dispatch(createMilestone(params));
        }
        else {
            this.props.dispatch(updateMilestone(params, id));
        }
    }

    render() {
        const {currentMilestone, buttonDisable} = this.state;
        const {milestones, projects} = this.props;

        let milestoneTitle = `Этап № ${currentMilestone.id || ''}`;
        let errors = Object.assign({}, milestones.errors);

        if(this.state.isNew) {
            milestoneTitle = 'Новый этап';
        }

        const projectOptions = Object.keys(projects.data).map(key => {
            let item = projects.data[key];
            return {value: item.id, label: item.title}
        });

        let otherMileStones = [{value: null, label: 'Не выбран'}]
        for(let key in milestones.data) {
            let item = milestones.data[key];
            if(item.id !== currentMilestone.id) {
                otherMileStones.push({value: item.id, label: item.title})
            }
        }

        const project = projectOptions.filter(item => item.value === currentMilestone.project)[0];
        const parent = otherMileStones.filter(item => item.value === currentMilestone.parent)[0];
        console.log(currentMilestone.state, 'currentMilestone.state')
        return (
            <div>
                <Header />

                <Container className="general-container">

                    <PageTitle title={milestoneTitle} />

                    <div className="line"/>

                    <Row>
                        <Col size="md" xs={6}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Group>
                                        <Form.Label>Заголовок</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={currentMilestone.title}
                                            onChange={(e) => this.handleOnChange('title', e.target.value)}
                                            placeholder="Название этапа"
                                        />
                                        <Form.Text className="text-muted">
                                            Объязательно для заполнения
                                        </Form.Text>
                                    </Form.Group>
                                    <ErrorStroke error={errors.title}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <SelectField
                                        label="Проект"
                                        options={projectOptions}
                                        defaultValue={project}
                                        onChange={(value) => this.handleOnChange('project', value)}
                                    />
                                    <ErrorStroke error={errors.project}/>
                                </Col>
                                <Col xs={6}>
                                    <SelectField
                                        label="Вложить в другой этап"
                                        options={otherMileStones}
                                        defaultValue={parent}
                                        onChange={(value) => this.handleOnChange('parent', value)}
                                    />
                                    <ErrorStroke error={errors.parent}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Form.Group className="task-edit__form">
                                        <Form.Label>План начало</Form.Label>
                                        <div>
                                            <DateTimePicker
                                                className="datetime-picker"
                                                value={currentMilestone.plan_start_at}
                                                onChange={(value) => this.handleOnChange('plan_start_at', value)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>План конец</Form.Label>
                                        <div>
                                            <DateTimePicker
                                                className="datetime-picker"
                                                value={currentMilestone.plan_end_at}
                                                onChange={(value) => this.handleOnChange('plan_end_at', value)}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Стоимость</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={currentMilestone.price}
                                            onChange={(e) => this.handleOnChange('price', e.target.value)}
                                            placeholder="Стоимость этапа"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Оплачено</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={currentMilestone.price_paid}
                                            onChange={(e) => this.handleOnChange('price_paid', e.target.value)}
                                            placeholder=""
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        <Form.Label>Планируемое время (часы)</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={currentMilestone.plan_estimate}
                                            onChange={(e) => this.handleOnChange('plan_estimate', e.target.value)}
                                            placeholder="В часах"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col xs={6}>
                                    <Form.Group>
                                        {/* <SelectField
                                            label="Статус"
                                            options={milestoneStatesOptions}
                                            defaultValue={currentMilestone.state}
                                            onChange={(value) => this.handleOnChange('state', value)}
                                        /> */}
                                        <div>Статус</div>
                                        <select value={currentMilestone.state} onChange={({ target }) => this.handleOnChange('state', target.value)}>
                                            {milestoneStatesOptions.map(item => {
                                                return <option key={item.value} value={item.value}>{item.label}</option>
                                            })}
                                        </select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6}>
                                    <Button
                                        variant="primary"
                                        size="md"
                                        disabled={milestones.isCreating || buttonDisable}
                                        onClick={this.handleSubmit}>
                                        {milestones.isCreating ? 'Сохраняется...' : 'Сохранить этап'}
                                    </Button>
                                </Col>
                            </Row>
                            {
                                !this.state.successMessage ? null :
                                <Row style={{marginTop: '20px'}}>
                                    <Col xs={6}>
                                        <Alert variant="success">{this.state.successMessage}</Alert>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        projects: state.projects,
        milestones: state.milestones,
        milestoneProcess: state.milestoneProcess,
    }
}

export default connect(mapStateToProps)(MilestoneEdit)