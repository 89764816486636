import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isSimilarObject } from '../../../utils/helpers';

export default class PeopleAreNotWorking extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const { users } = this.props;

        let lazyPeople = [];
        for(let id in users) {
            if(users[id].is_idler) {
                let names = users[id].first_name + ' ' + users[id].last_name
                lazyPeople.push(names)
            }
        }

        return (
            <Row style={{marginTop:'-6px',marginBottom:'7px',fontSize:'14px',color:'red'}}>
                <Col>
                    Бездействуют: {lazyPeople.length ? lazyPeople.join(", ") : 'Нет бездействующих'}
                </Col>
            </Row>
        )
    }
}

PeopleAreNotWorking.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    users: PropTypes.object.isRequired,
};