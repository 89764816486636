import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { isSimilarObject } from '../../../utils/helpers';
import { projectStates } from '../../../utils/Constants';

export default class TasksTable extends Component {

    shouldComponentUpdate(nextProps) {
        if(isSimilarObject(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    render() {
        const { currentProject, contractor, milestoneLength } = this.props;

        let infoArray = [];
        if(contractor) infoArray.push(`Ответственный ${contractor.first_name} ${contractor.last_name}`);
        else infoArray.push(`Ответственный не указан`);
        infoArray.push(`Количество этапов: ${milestoneLength}`);

        let stateObj = projectStates[currentProject.state] || {};

        return (
            <Row className="task-state__container">
                <Col xs={12}>
                    <Button
                        title={stateObj.title}
                        variant={stateObj.color}
                        size="sm"
                        disabled>
                        {stateObj.shortTitle}
                    </Button>
                    <span className="task-created">
                        {infoArray.join(", ")}
                    </span>
                </Col>
            </Row>
        )
    }
}

TasksTable.propTypes = {
    // You can declare that a prop is a specific JS primitive. By default, these
    // are all optional.
    milestoneLength: PropTypes.number.isRequired,
    currentProject: PropTypes.object.isRequired,
};