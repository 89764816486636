import * as types from '../actions/actionTypes';
import initialState from './initialState';
import _ from 'lodash';

export default function reducer(state = initialState.checkList, action) {
    switch (action.type) {
        case types.LOAD_CHECKLIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.LOAD_CHECKLIST_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    ..._.mapKeys(action.payload.results, 'id')
                },
                ids: [..._.union(state.ids, [..._.map(action.payload.results, 'id')])],
                count: action.payload.count,
                next: action.payload.next,
                timestamp: new Date(),
            });
        case types.LOAD_CHECKLIST_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.UPDATE_CHECKLIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.UPDATE_CHECKLIST_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: (state.count || 0),
                next: state.next,
            });
        case types.UPDATE_CHECKLIST_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        case types.SET_STATE_CHECKLIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.SET_STATE_CHECKLIST_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: (state.count || 0),
                next: state.next,
            });
        case types.SET_STATE_CHECKLIST_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });

        
        case types.CREATE_CHECKLIST_REQUEST:
            return Object.assign({}, state, {
                isCreating: true,
            });
        case types.CREATE_CHECKLIST_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isCreating: false,
                data: {
                    ...state.data,
                    [action.payload.id]: action.payload
                },
                ids: [..._.union(state.ids, [action.payload.id])],
                count: ((state.count || 0) + 1),
                next: state.next,
            });
        case types.CREATE_CHECKLIST_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isCreating: false,
            });
        
        
        case types.DELETE_CHECKLIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.DELETE_CHECKLIST_SUCCESS:
            return Object.assign({}, state, {
                errors: null,
                isFetching: false,
                data: _.omit(state.data, action.payload.id),
                ids: state.ids.filter(item => item !== action.payload.id),
                count: state.count > 0 ? (state.count - 1) : state.count,
                next: state.next,
            });
        case types.DELETE_CHECKLIST_FAILURE:
            return Object.assign({}, state, {
                errors: action.errors,
                isFetching: false,
            });
        
        
        default:
            return state;
    }
}